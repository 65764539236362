// src/utils/cryptoUtils.js
import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY || "DFHFLGFHGOHFWU12345";

export const decode = (encryptedText) => {
  const decryptedText = encryptedText
    .replace(/-/g, "+")
    .replace(/_/g, "/")
    .replace(/~/g, "=");
  const bytes = CryptoJS.AES.decrypt(decryptedText, secretKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(originalText);
};