import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import EntityMasterForm from "../MasterFrom/EntityMasterForm";
import TitleCard from "../Card/TitleCard";
import Avatar from "../components/Avatar";
import { Link } from "react-router-dom";
import InputText from "../Input/InputText";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import Dropdown from "../Input/DropDown";
import share from "../Icons/share_3857028.png";
import { useAuth } from "../Context/AuthContext";

const UserProfileCard = () => {
  const { userdata } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newdata, setNewdata] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(getOneWeekAgoDate());
  const [toDate, setToDate] = useState(getDate());
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(
    userdata?.UserID || 0
  );

  useEffect(() => {
    if (location.state) {
      const { UserID, fromDate, ToDate } = location.state;
      setSelectedEmployee(UserID);
      if (ToDate) setToDate(ToDate);
      if (fromDate) setFromDate(fromDate);
    }
  }, [location.state]);

  useEffect(() => {
    fetchDropdownOptions();
    fetchUserData();
  }, [userdata.UserID, newdata, selectedEmployee, fromDate, toDate]);

  useEffect(() => {
    console.log("filteruser",)
    const filtered = users.filter((user) =>
      user.Name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchQuery, users]);

  const fetchUserData = async () => {
    setLoading(true);
    if (!selectedEmployee) {
      setSelectedEmployee(userdata.UserID);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/AtlysEntityManagementapi/${userdata.UserID}`,
        {
          UserID: selectedEmployee,
          IsSuperUser: userdata.IsSuperUser,
          ShowMore: newdata ? "true" : "false",
          FromDate: fromDate,
          ToDate: toDate,
        }
      );

      setUsers(response.data.data || []);
     
      setFilteredUsers(response.data.data || []);
      console.log("response.data.data || []", response.data.data || []);
    } catch (err) {
      setError("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  const fetchDropdownOptions = async () => {
    try {
      let strUserIDorNull =
        userdata.IsSuperUser === "0" ? userdata.UserID : "00";
      const dropdownResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/BindGridusermaster_get?UserID=${strUserIDorNull}`
      );
      setDropdownOptions(dropdownResponse.data);
    } catch (err) {
      setError("Failed to fetch dropdown data");
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setSelectedEmployee(e.target.value);
  };

  const updateFormValue = (name, value) => {
    if (name === "FromDate") {
      setFromDate(formatDateFromInput(value));
    } else if (name === "ToDate") {
      setToDate(formatDateFromInput(value));
    }
  };

  const handleLeadPreview = (user) => {
    navigate("/Representatives/CardDetail", {
      state: { user, toDate, UserID: selectedEmployee, fromDate },
    });
  };

  // After error : http://localhost:3000/Representatives/CardDetail

  function formatDateToInput(dateStr) {
    const [day, month, year] = dateStr.split("-");
    const monthIndex = new Date(`${month} 1, 2000`).getMonth() + 1;
    const monthFormatted = monthIndex.toString().padStart(2, "0");
    return `${year}-${monthFormatted}-${day}`;
  }

  function formatDateFromInput(dateStr) {
    const [year, month, day] = dateStr.split("-");
    const monthName = new Date(`${year}-${month}-${day}`)
      .toLocaleString("en-US", { month: "short" })
      .toLowerCase();
    return `${day}-${monthName}-${year}`;
  }

  function getDate() {
    const today = new Date();
    return formatDateFromInput(today.toISOString().split("T")[0]);
  }

  function getOneWeekAgoDate() {
    const today = new Date();
    today.setDate(today.getDate() - 7);
    return formatDateFromInput(today.toISOString().split("T")[0]);
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <TitleCard>
      <div className=" flex justify-between">
        <h1 className="text-xl font-semibold ">Representatives</h1>
        <div className="flex justify-end gap-4">
          {userdata?.IsSuperUser !== "0" && (
            <Link
              to="/Dashboard"
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-3 sm:px-4 rounded-full inline-flex items-center"
            >
              <MdOutlineDashboardCustomize />
            </Link>
          )}
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-3 sm:px-4 rounded-full inline-flex items-center"
            onClick={() => setNewdata(!newdata)}
          >
            <IoIosAddCircleOutline />
          </button>
        </div>
      </div>
      <div>
        {newdata ? (
          <div>
            {/* <h1 className="text-xl font-semibold py-4">Representatives</h1> */}
            <EntityMasterForm changeForms={() => setNewdata(false)} />
          </div>
        ) : (
          <div>
            <div onSubmit={(e) => e.preventDefault()}>
              <div className="flex flex-col md:flex-row justify-between gap-2 mt-4">
                <Dropdown
                  name="EmpID"
                  labelTitle="Employee"
                  value={selectedEmployee}
                  options={dropdownOptions || []}
                  labelText="UserName"
                  labelValue="UserID"
                  onChange={handleDropdownChange}
                />
                <InputText
                  name="FromDate"
                  type="date"
                  updateFormValue={updateFormValue}
                  labelTitle="From Date"
                  value={formatDateToInput(fromDate)}
                />
                <InputText
                  name="ToDate"
                  type="date"
                  updateFormValue={updateFormValue}
                  labelTitle="To Date"
                  value={formatDateToInput(toDate)}
                />
                <button
                  className="px-6 py-2 rounded bg-gray-800 text-white hover:bg-neutral-400 border-none my-7 hover:text-black btn-sm normal-case"
                  onClick={fetchUserData}
                >
                  Search
                </button>
              </div>
            </div>
            <div>
              <input
                type="text"
                placeholder="Search Users..."
                value={searchQuery}
                onChange={handleSearch}
                className={` border-slate-300
                "cursor-not-allowed" : "cursor-default"
               rounded-md h-10 p-0 pl-3 border-2 sm:mt-2 w-full my-2 `}
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
              {filteredUsers.map((user) => (
                <div
                  onClick={() => handleLeadPreview(user)}
                  key={user.UserID}
                  className="bg-white cursor-pointer dark:bg-slate-800 rounded-lg shadow-md hover:shadow-xl hover:scale-105 ease-out duration-100 overflow-hidden border"
                >
                  <figure className="relative p-3 flex justify-between">
                    <div className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-lg">
                      <div className="text-gray-600 hover:text-gray-900 dark:text-gray-300 cursor-pointer">
                        <img src={share} alt="" className="h-4" />
                      </div>
                    </div>
                    <div className="">
                      <div className="flex gap-4">
                        <Avatar name={user?.Name || "User"} />
                        <div>
                          <div className="text-lg font-semibold text-slate-900 dark:text-slate-200">
                            {user.Name || "Name Not Available"}
                          </div>
                          <div className="text-slate-600 dark:text-slate-300 text-xs">
                            {user.Designation || "NA"}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between flex-col">
                        <div className="mt-2">
                          <div className="flex items-center space-x-1 text-xs text-blue-900 dark:text-gray-300 p-1">
                            <FaPhoneAlt />
                            <span>{user.Phone || "NA"}</span>
                          </div>
                          <div className="flex items-center space-x-1 text-xs text-blue-900 dark:text-gray-300 p-1">
                            <FaEnvelope />
                            <span>{user.Email || "NA"}</span>
                          </div>
                          <div className="flex gap-2 text-xs p-1">
                            <p>Type</p>
                            <div className="text-xs text-gray-500">
                              {user.Type || "NA"}
                            </div>
                          </div>
                          {/* <div className="flex gap-2 text-xs p-1">
                            <p>Country</p>
                            <div className="text-xs text-gray-500">
                              {user.DestinationCountry || "Country Not Available"}
                            </div>
                          </div> */}
                          <div className="flex gap-2 text-xs p-1">
                            <p>City</p>
                            <div className="text-xs text-gray-500">
                              {user.City || "City Not Available"}
                            </div>
                          </div>
                          <div className="flex gap-2 text-xs p-1">
                            <p>Client Country</p>
                            <div className="text-xs text-gray-500">
                              {user.ClientCountry || "City Not Available"}
                            </div>
                          </div>
                          <div className="flex gap-2 text-xs p-1">
                            <p>Source Country</p>
                            <div className="text-xs text-gray-500">
                              {user.SourceCountry || "City Not Available"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </TitleCard>
  );
};

export default UserProfileCard;
