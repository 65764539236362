import React from "react";
import TitleCard from "../Card/TitleCard";
import InputText from "../Input/InputText";

const ChangePassword = () => {
  return (
    <>
      <TitleCard title={"You want to change password"}>
        <form action="">
          <InputText  labelTitle={"Old Password"}  ></InputText>

          <InputText labelTitle={"New Password"}></InputText>

          <div className="flex flex-col md:flex-row justify-between mt-4 gap-4">
              <button   to= "/Home" className="btn w-full md:w-24">
                Back
              </button>
              <button
                type="submit"
                className="btn text-white  bg-[#0a3b93] badge-primary w-full md:w-24"
              >
                Submit
              </button>
            </div>
        </form>
      </TitleCard>
    </>
  );
};

export default ChangePassword;
