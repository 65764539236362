// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { BellIcon,CalendarIcon, UserCircleIcon,Bars3Icon,} from "@heroicons/react/24/outline";
// import { FaUser, FaSignOutAlt } from "react-icons/fa";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
// import Logo from "../images/atlys1.webp";
// import { useAuth } from "../Context/AuthContext";
// import { useNavigate } from "react-router-dom";
// import Avatar from "./Avatar";
// import { FaBars } from "react-icons/fa";
// import Notification from "./Notification";
// const ConfirmationBanner = ({ onConfirm, onCancel }) => {
//   return (
//     <div className="  fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
//       <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-sm m-4">
//         <h2 className="text-lg font-semibold  text-black">
//           Confirm Logout
//         </h2>
//         <p className="mb-4 text-black">Are you sure you want to log out?</p>
//         <div className="flex justify-end gap-4">
//           <button
//             onClick={onConfirm}
//             className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
//           >
//             Logout
//           </button>
//           <button
//             onClick={onCancel}
//             className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400"
//           >
//             Cancel
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Header = ({ toggleSidebar, isSidebarVisible, user }) => {
//   const [isCalendarOpen, setIsCalendarOpen] = useState(false);
//   const [isSearchOpen, setIsSearchOpen] = useState(false);
//   const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
//   const [showConfirm, setShowConfirm] = useState(false);
//   const [isNotificationsOpen, setIsNotificationsOpen] = useState(false); // State for notifications
//   const [notifications, setNotifications] = useState([]); // State for notification data

//   const navigate = useNavigate();
//   const { userdata, logout } = useAuth();

//   const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);
//   const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
//   const toggleUserDropdown = () => setIsUserDropdownOpen(!isUserDropdownOpen);
//   const handleLogout = () => setShowConfirm(true);
//   const handleChangePassword = () => console.log("Change Password clicked");
//   const handleProfile = () => console.log("Profile clicked");

//   const toggleNotifications = () =>
//     setIsNotificationsOpen(!isNotificationsOpen);

//   const confirmLogout = () => {
//     setShowConfirm(false);
//     logout();
//     navigate("/login");
//   };

//   const cancelLogout = () => setShowConfirm(false);

//   return (
//     <header className="bg-transparent text-black shadow-md relative">
//       <div className="container mx-auto flex md:flex-row items-center justify-between p-4">
//         <div className="md:flex items-center space-x-4 md:space-x-6">
//          <div className="flex  items-center gap-2 ">
//          <div>
//           {!isSidebarVisible && (
//             <button
//               onClick={toggleSidebar}
//               className="items-center text-gray-600 p-2 rounded-full hover:bg-gray-200 hover:text-gray-800 transition ease-in-out duration-300"
//             >
//               <FaBars className="w-5 h-5" />
//             </button>
//           )}
//           </div>

//           {/* <div>
//             <p className="block md:hidden text-sm">{userdata.UserName}</p>
//           </div> */}
//          </div>
//         </div>

//         <div className="flex items-center space-x-4 md:space-x-6">
//           <button onClick={toggleNotifications} className="p-2 relative">
//             <BellIcon className="w-6 h-6 text-black hover:text-gray-400" />
//             {notifications.length > 0 && (
//               <span className="absolute top-0 right-0 block w-2.5 h-2.5 bg-red-500 rounded-full"></span>
//             )}
//           </button>
//           {isNotificationsOpen && (
//             <div className="   absolute right-0 top-full m-3 bg-white rounded-lg text-black border border-gray-300 shadow-lg z-50  w-auto">
//               <Notification />
//             </div>
//           )}
//           <button onClick={toggleCalendar} className="p-2">
//             <CalendarIcon className="w-6 h-6 text-black hover:text-gray-400 rounded-lg" />
//           </button>
//           <button
//             onClick={toggleUserDropdown}
//             className="flex items-center space-x-2 p-2"
//           >
//             <UserCircleIcon className="w-6 h-6 text-black hover:text-gray-400" />
//             {user && !user.IsAdministrator && user.UserName && (
//               <span className="hidden md:inline text-sm">{user.UserName}</span>
//             )}
//           </button>
//         </div>
//       </div>

//       {isCalendarOpen && (
//         <div className="  rounded-lg m-2 absolute right-0 top-full mt-2 p-4 bg-white text-black border border-gray-300 shadow-lg z-50 w-full max-w-xs md:max-w-sm">
//           <Calendar />
//         </div>
//       )}

//       {isSearchOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-40">
//           <div className="bg-white p-4 rounded-lg w-full max-w-xs">
//             <div className="relative">
//               <input
//                 type="text"
//                 placeholder="Search..."
//                 className="w-full pl-10 pr-4 py-2 border-gray-300 rounded-full bg-gray-300 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
//               />
//               <span className="absolute inset-y-0 left-0 flex items-center pl-3">
//                 <svg
//                   className="w-5 h-5 text-gray-800"
//                   xmlns="http://www.w3.org/2000/svg"
//                   viewBox="0 0 16 16"
//                   fill="currentColor"
//                 >
//                   <path
//                     fillRule="evenodd"
//                     d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
//                     clipRule="evenodd"
//                   />
//                 </svg>
//               </span>
//             </div>
//             <button
//               onClick={toggleSearch}
//               className="mt-4 px-4 py-2 bg-gray-700 text-white rounded-lg"
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}

//       {isUserDropdownOpen && (
//         <div className="absolute right-0 top-full m-4 p-4 rounded-lg bg-white text-black border border-gray-300 shadow-lg z-50 w-48">
//           <div className="flex items-center mb-2 border-gray-200">
//             <Avatar name={userdata?.UserName || "User"} />
//             <div className="ml-2">
//               <p className="text-sm capitalize font-semibold">
//                 {userdata?.UserName || "User"}
//               </p>
//             </div>
//           </div>
//           <ul>
//             <Link
//               to="/profile"
//               className="flex items-center py-1 px-2 text-blue-500 hover:rounded-md hover:bg-gray-200"
//               onClick={handleProfile}
//             >
//               <FaUser className="h-5 mr-2 text-sm text-blue-500" />
//               Profile
//             </Link>

//             <button
//               onClick={handleLogout}
//               className="flex items-center py-1 px-2 text-red-500 hover:rounded-md hover:bg-gray-200 w-full text-left"
//             >
//               <FaSignOutAlt className="h-5 mr-2 text-red-500" />
//               Logout
//             </button>
//           </ul>
//         </div>
//       )}

//       {showConfirm && (
//         <ConfirmationBanner onConfirm={confirmLogout} onCancel={cancelLogout} />
//       )}
//     </header>
//   );
// };

// export default Header;

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BellIcon,
  CalendarIcon,
  UserCircleIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";
import { FaUser, FaSignOutAlt } from "react-icons/fa";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Logo from "../images/atlys1.webp";
import { useAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Avatar from "./Avatar";
import { FaBars } from "react-icons/fa";
import Notification from "./Notification";
import ConfirmationBanner from "../Loginpage/Logout";
import { motion } from "framer-motion";
// const ConfirmationBanner = ({ onConfirm, onCancel }) => {
//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
//       <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-sm m-4">
//         <h2 className="text-lg font-semibold text-black">Confirm Logout</h2>
//         <p className="mb-4 text-black">Are you sure you want to log out?</p>
//         <div className="flex justify-end gap-4">
//           <button onClick={onConfirm} className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600">
//             Logout
//           </button>
//           <button onClick={onCancel} className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400">
//             Cancel
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

const Header = ({ toggleSidebar, isSidebarVisible, user }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const navigate = useNavigate();
  const { userdata, logout } = useAuth();

  const headerRef = useRef(null); // Create a ref for the header

  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
  const toggleUserDropdown = () => setIsUserDropdownOpen(!isUserDropdownOpen);
  const handleLogout = () => setShowConfirm(true);
  const handleProfile = () => console.log("Profile clicked");
  const toggleNotifications = () =>
    setIsNotificationsOpen(!isNotificationsOpen);

  const confirmLogout = () => {
    setShowConfirm(false);
    logout();
    navigate("/login");
  };

  const cancelLogout = () => setShowConfirm(false);

  // Handle click outside to close all toggles
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
        setIsSearchOpen(false);
        setIsUserDropdownOpen(false);
        setIsNotificationsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headerRef]);

  return (
    <header
      ref={headerRef}
      className="bg-transparent text-black shadow-md relative"
    >
      <div className="container mx-auto flex md:flex-row items-center justify-between p-4">
        <div className="md:flex items-center space-x-4 md:space-x-6">
          <div className="flex items-center gap-2">
            <div>
              {!isSidebarVisible && (
                <button
                  onClick={toggleSidebar}
                  className="items-center text-gray-600 p-2 rounded-full hover:bg-gray-200 hover:text-gray-800 transition ease-in-out duration-300"
                >
                  <FaBars className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4 md:space-x-6">
          <button onClick={toggleNotifications} className="p-2 relative">
            <BellIcon className="w-6 h-6 text-black hover:text-gray-400" />
            {notifications.length > 0 && (
              <span className="absolute top-0 right-0 block w-2.5 h-2.5 bg-red-500 rounded-full"></span>
            )}
          </button>
          {/* {isNotificationsOpen && (
            <div className="absolute right-0 top-full m-3 bg-white rounded-lg text-black border border-gray-300 shadow-lg z-50 w-auto">
              <Notification />
            </div>
          )} */}

          {isNotificationsOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="absolute right-0 top-full m-3 bg-white rounded-lg text-black border border-gray-300 shadow-lg z-50 w-auto"
            >
              <Notification />
            </motion.div>
          )}
          <button onClick={toggleCalendar} className="p-2">
            <CalendarIcon className="w-6 h-6 text-black hover:text-gray-400 rounded-lg" />
          </button>
          <button
            onClick={toggleUserDropdown}
            className="flex items-center space-x-2 p-2"
          >
            <UserCircleIcon className="w-6 h-6 text-black hover:text-gray-400" />
            {user && !user.IsAdministrator && user.UserName && (
              <span className="hidden md:inline text-sm">{user.UserName}</span>
            )}
          </button>
        </div>
      </div>

      {/* {isCalendarOpen && (
        <div className="rounded-lg m-2 absolute right-0 top-full mt-2 p-4 bg-white text-black border border-gray-300 shadow-lg z-50 w-full max-w-xs md:max-w-sm">
          <Calendar />
        </div>
      )} */}
      {isCalendarOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="rounded-lg m-2 absolute right-0 top-full mt-2 p-4 bg-white text-black border border-gray-300 shadow-lg z-50 w-full max-w-xs md:max-w-sm"
        >
          <Calendar />
        </motion.div>
      )}
      {isSearchOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-40">
          <div className="bg-white p-4 rounded-lg w-full max-w-xs">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="w-full pl-10 pr-4 py-2 border-gray-300 rounded-full bg-gray-300 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  className="w-5 h-5 text-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </div>
            <button
              onClick={toggleSearch}
              className="mt-4 px-4 py-2 bg-gray-700 text-white rounded-lg"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* {isUserDropdownOpen && (
        <div className="absolute right-0 top-full m-4 p-4 rounded-lg bg-white text-black border border-gray-300 shadow-lg z-50 w-48">
          <div className="flex items-center mb-2 border-gray-200">
            <Avatar name={userdata?.UserName || "User"} />
            <div className="ml-2">
              <p className="text-sm capitalize font-semibold">{userdata?.UserName || "User"}</p>
            </div>
          </div>
          <ul>
            <Link to="/profile" className="flex items-center py-1 px-2 text-blue-500 hover:rounded-md hover:bg-gray-200" onClick={handleProfile}>
              <FaUser className="h-5 mr-2 text-sm text-blue-500" />
              Profile
            </Link>

            <button onClick={handleLogout} className="flex items-center py-1 px-2 text-red-500 hover:rounded-md hover:bg-gray-200 w-full text-left">
              <FaSignOutAlt className="h-5 mr-2 text-red-500" />
              Logout
            </button>
          </ul>
        </div>
      )} */}
      {isUserDropdownOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="absolute right-0 top-full m-4 p-4 rounded-lg bg-white text-black border border-gray-300 shadow-lg z-50 w-48"
        >
          <div className="flex items-center mb-2 border-gray-200">
            <Avatar name={userdata?.UserName || "User"} />
            <div className="ml-2">
              <p className="text-sm capitalize font-semibold">
                {userdata?.UserName || "User"}
              </p>
            </div>
          </div>
          <ul>
            <Link
              to="/profile"
              className="flex items-center py-1 px-2 text-blue-500 hover:rounded-md hover:bg-gray-200"
              onClick={handleProfile}
            >
              <FaUser className="h-5 mr-2 text-sm text-blue-500" />
              Profile
            </Link>

            <button
              onClick={handleLogout}
              className="flex items-center py-1 px-2 text-red-500 hover:rounded-md hover:bg-gray-200 w-full text-left"
            >
              <FaSignOutAlt className="h-5 mr-2 text-red-500" />
              Logout
            </button>
          </ul>
        </motion.div>
      )}

      {showConfirm && (
        <ConfirmationBanner onConfirm={confirmLogout} onCancel={cancelLogout} />
      )}
    </header>
  );
};

export default Header;
