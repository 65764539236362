
import React, { useState } from "react";
import TitleCard from "../Card/TitleCard";
import InputText from "../Input/InputText";
import { useUser } from "../Context/UserContext";
import { useAuth } from "../Context/AuthContext";
import Avatar from "./Avatar";

const Profile = () => {
  const { user } = useUser();
  const { userdata } = useAuth();
  const [image, setImage] = useState(null);
  const [inputData, setInputData] = useState({
    UserID: userdata?.UserID || "",
    UserName: userdata?.UserName || '',
    EmailID: userdata?.EmailID || '',
    ContactNo: userdata?.ContactNo || '',
  });

  const dataURLToBlob = (dataURL) => {
    const [header, base64] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(base64);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(dataURLToBlob(reader.result));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  const updateFormValue = (name, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('UserID', inputData.UserID);
    formData.append('UserName', inputData.UserName);
    formData.append('EmailID', inputData.EmailID);
    formData.append('ContactNo', inputData.ContactNo);

    if (image) {
      formData.append('profileImage', image, 'profileImage.png');
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/userprofileupdate/${inputData.UserID}`, {
        method: 'PUT',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Network response was not ok:', errorText);
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Profile updated successfully:', result);
      alert("Profile updated successfully");
    } catch (error) {
      console.error('Error updating profile:', error);
      alert("Error updating profile: " + error.message);
    }
  };

  if (user && user.role === "admin") {
    return <p>Admin profile data is not visible.</p>;
  }

  return (
    <div className="h-full my-4">
      <form onSubmit={handleSubmit} className="border-b-2 block md:flex">
        <div className="w-full md:w-2/5 p-4 sm:p-6 lg:p-8 bg-white shadow-md">
          <div className="flex justify-between">
            <span className="text-xl font-semibold block">Admin Profile</span>
          </div>

          <span className="text-gray-600">
            This information is secret so be careful
          </span>

          <div className="w-auto p-8 mx-2 flex justify-center">
            {image ? (
              <img
                id="showImage"
                className="w-32 h-32 rounded-full object-cover"
                src={URL.createObjectURL(image)}
                alt="profile"
              />
            ) : (
              <div>
                <Avatar
                  name={inputData.UserName || "User"}
                  style={{ width: '100px', height: '100px' }}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col md:flex-row justify-center items-center mt-4 gap-4">
            <label className="cursor-pointer bg-blue-500 text-white py-2 px-4 rounded text-center">
              Change Image
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageChange}
              />
            </label>

            {image && (
              <button
                className="bg-red-500 text-white py-2 px-4 rounded"
                onClick={handleRemoveImage}
                type="button"
              >
                Remove Image
              </button>
            )}
          </div>
        </div>

        <div className="w-full md:w-3/5 lg:p-8 bg-white lg:ml-4 shadow-md">
          <div className="rounded shadow p-6">
            <div className="pb-6">
              <InputText
                type="text"
                name="UserName"
                labelTitle="User Name"
                value={inputData.UserName}
                className="bg-white border border-gray-300 rounded-md p-2"
                updateFormValue={updateFormValue}
              />
            </div>
            <div className="pb-4">
              <InputText
                type="email"
                name="EmailID"
                labelTitle="EmailID"
                value={inputData.EmailID}
                className="bg-white border border-gray-300 rounded-md p-2"
                updateFormValue={updateFormValue}
              />
            </div>
            <div className="pb-4">
              <InputText
                type="text"
                name="ContactNo"
                labelTitle="Contact No"
                value={inputData.ContactNo}
                className="bg-white border border-gray-300 rounded-md p-2"
                updateFormValue={updateFormValue}
              />
            </div>
            <span className="text-gray-600 pt-4 block opacity-70">
              Personal login information of your account
            </span>
          </div>

          <div className="p-6 flex justify-end">
            <button
              type="submit"
              className="-mt-2 text-md font-bold text-white bg-gray-700 rounded-lg px-5 py-2 hover:bg-gray-800"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Profile;
