const ConfirmationBanner = ({ onConfirm, onCancel }) => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-sm m-4">
          <h2 className="text-lg font-semibold text-black text-center">Confirm to logout ? </h2>
          <p className="mb-4 text-black text-center"> Confirm to logout from the current account?</p> <hr />
          <div className="flex  justify-between gap-4 px-8">
            <button onClick={onConfirm} className=" text-red-600 px-4 py-2 rounded-md">
            Confirm 
            </button>
            <button onClick={onCancel} className=" px-4 py-2 rounded-md ">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  export default ConfirmationBanner;