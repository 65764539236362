import React, { useMemo } from 'react';

const getRandomColor = () => {
  // const colors = ['#FF5733', '#33FF57', '#3357FF', '#F99999', '#F5B041', '#D4AC0D'];
   //const colors = ["#8B8B8B" ,"#4B4E57","#949897"];
    const colors = ["#050c9c" ,"#3572ef","#3abef9","#a7e6ff"];
  return colors[Math.floor(Math.random() * colors.length)];
};


const Avatar = React.memo(({ name }) => {
  // Extract the first initials from the name
  const initials = useMemo(() => {
    return name
      .split(' ')
      .map(word => word.charAt(0))
      .join('')
      .toUpperCase();
  }, [name]); 
  // Create a random background color for the avatar
  const backgroundColor = useMemo(() => getRandomColor(), []);

  return (
    <div
      className="flex items-center justify-center w-12 p-3 h-12 rounded-full text-white font-bold text-xl"
      style={{ backgroundColor }}
    >
      {initials}
    </div>
  );
});

export default Avatar;
