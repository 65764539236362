import React, { useState, useEffect } from "react";
import axios from "axios";
import InputText from "../Input/InputText";
// import TextAreaInput from "../Input/TextAreaInput";
import CryptoJS from "crypto-js";
import Dropdown from "../Input/DropDown";

const secretKey = process.env.REACT_APP_SECRET_KEY;
const BranchMasterForm = ({ changeForms, selectedRowData }) => {
  const [inputdata, setInputData] = useState(selectedRowData);
  const [dropdownData, setDropdownData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({
    company: "",
    country: "",
    state: "",
  });
  const [errors, setErrors] = useState({});

  // const validateField = (name, value) => {
  //   if (!value || value.trim() === "") {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_ERROR}`,
  //     }));
  //     return false;
  //   }

  //   // Additional specific validations
  //   switch (name) {
  //     case "EmailID":
  //       if (!emailRegex.test(value)) {
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_EMAIL}`,
  //         }));
  //         return false;
  //       }
  //       break;

  //     case "Description":
  //       if (!ValidationRegex.test(value)) {
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_Description}`,
  //         }));
  //         return false;
  //       }
  //       break;
  //     case "Code":
  //       if (!CodeValidationRegex.test(value)) {
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_CODE}`,
  //         }));
  //         return false;
  //       }
  //       break;

  //     case "Address1":
  //       if (!addressValidationRegex.test(value)) {
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_CODE}`,
  //         }));
  //         return false;
  //       }
  //       break;
  //     case "ContactNo":
  //       if (!contactNoRegex.test(value)) {
  //         setErrors((prevErrors) => ({
  //           ...prevErrors,
  //           [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_CONTACT}`,
  //         }));
  //         return false;
  //       }
  //       break;

  //     default:
  //       break;
  //   }

  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: "",
  //   }));
  //   return true;
  // };

  console.log(selectedRowData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const isValid =
    //   validateField("Code", inputdata.Code) &
    //   validateField("BranchCode", inputdata.BranchCode) &
    //   validateField("Priority", inputdata.Priority) &
    //   validateField("ContactNo", inputdata.ContactNo) &
    //   validateField("Address1", inputdata.Address1) &
    //   validateField("EmailID", inputdata.EmailID);

    // if (!isValid) {
    //   // If any field is invalid, prevent form submission
    //   return;
    // }

    // const secretKey = "DFHFLGFHGOHFWU12345";
    const customIV = CryptoJS.lib.WordArray.random(16); // 16 bytes IV for AES encryption
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(inputdata),
      secretKey,
      { iv: customIV }
    ).toString();

    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/updateBranch`;
      let method = "PUT";

      if (selectedRowData && selectedRowData.BranchID) {
        url += `/${selectedRowData.BranchID}`;
      } else {
        url = `${process.env.REACT_APP_API_BASE_URL}/BranchMasterPost`;
        method = "POST";
      }

      const response = await axios({
        method: method,
        url: url,
        data: { encryptedData },
      });
      console.log("branchmater", inputdata);
      console.log(response.data);
      alert("successfully");
      e.target.reset();
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleBack = () => {
    changeForms();
  };

  const updateFormValue = (name, value) => {
    // Update inputdata state with the new value
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Remove validation error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/GetBindDropdownForCountry`
      );
      const apidata = {
        GetCompany: response.data.GetCompany,
        GetCountries: response.data.GetCountries,
        Getstates: response.data.Getstates,
      };
      console.log("branchdropdown ", apidata);
      setDropdownData(apidata);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    console.log("name:", name, " value:", value);
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
    setInputData((prevData) => ({
      ...prevData,
      [name]: typeof value === "string" ? value : String(value),
    }));
    // Remove validation error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  return (
    <div className="py-3">
      {/* <TitleCard title={"BranchMaster"}> */}
        <form onSubmit={handleSubmit}>
          {/* General Information */}
          <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1">
            <div className="gap-6">
              <div className="font-semibold text-lg">General Information</div>

              <Dropdown
                labelTitle="Company"
                name="company"
                options={dropdownData.GetCompany || []}
                value={selectedOptions.company}
                onChange={handleDropdownChange}
                sup
              />
              {errors.company && (
                <div className="text-red-500">{errors.company}</div>
              )}
              <InputText
                name="branchCode"
                labelTitle="Branch Code"
                placeholder="Branch Code"
                Value={selectedRowData.BranchCode}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.BranchCode && (
                <div className="text-red-500">{errors.BranchCode}</div>
              )}
              <InputText
                name="branchName"
                labelTitle="Branch Name"
                defaultValue={inputdata.BranchName}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.BranchName && (
                <div className="text-red-500">{errors.BranchName}</div>
              )}
              <InputText
                name="branchEmail"
                labelTitle="Branch Email"
                defaultValue={inputdata.BranchEmail}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.BranchEmail && (
                <div className="text-red-500">{errors.BranchEmail}</div>
              )}
              <InputText
                type="number"
                name="stdCode"
                labelTitle="STD Code"
                defaultValue={inputdata.STDCode}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.STDCode && (
                <div className="text-red-500">{errors.STDCode}</div>
              )}
              <InputText
                type="number"
                name="telNo1"
                labelTitle="Phone 1"
                defaultValue={inputdata.TelNo1}
                updateFormValue={updateFormValue}
              />
              {errors.TelNo1 && (
                <div className="text-red-500">{errors.TelNo1}</div>
              )}
              <InputText
                type="number"
                name="telNo2"
                labelTitle=" Phone 2"
                defaultValue={inputdata.TelNo2}
                updateFormValue={updateFormValue}
              />
              <InputText
                type="number"
                name="faxNo1"
                labelTitle="Fax 1"
                defaultValue={inputdata.FaxNo1}
                updateFormValue={updateFormValue}
              />
              <InputText
                type="number"
                name="faxNo2"
                labelTitle="Fax 2"
                defaultValue={inputdata.FaxNo2}
                updateFormValue={updateFormValue}
              />
              <InputText
                name="panNo"
                labelTitle="Pan No"
                defaultValue={inputdata.PanNo}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.PanNo && (
                <div className="text-red-500">{errors.PanNo}</div>
              )}
              <InputText
                type=""
                name="registrationNo"
                labelTitle="Registration No"
                defaultValue={inputdata.RegistrationNo}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.RegistrationNo && (
                <div className="text-red-500">{errors.RegistrationNo}</div>
              )}
              <InputText
                type="number"
                name="vatNo"
                labelTitle=" Vat No"
                defaultValue={inputdata.VatNo}
                updateFormValue={updateFormValue}
              />
              <InputText
                type="number"
                name="tinNo"
                labelTitle="TinNo"
                defaultValue={inputdata.TinNo}
                updateFormValue={updateFormValue}
              />
              {/* <TextAreaInput
                name="officeTimeTable"
                labelTitle="Office Time"
                defaultValue={inputdata.OfficeTimeTable}
                updateFormValue={updateFormValue}
              /> */}
            </div>
            {/* Address Information */}
            <div className="gap-6">
              <div className="font-semibold text-lg">Address Information</div>

              <InputText
                name="cpName"
                labelTitle="Contact Person Name"
                defaultValue={inputdata.CPName}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.CPName && (
                <div className="text-red-500">{errors.CPName}</div>
              )}
              <InputText
                type="number"
                name="cpMobileNo"
                labelTitle=" Contact No"
                defaultValue={inputdata.CPMobileNo}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.CPMobileNo && (
                <div className="text-red-500">{errors.CPMobileNo}</div>
              )}
              <InputText
                name="cpEmailID"
                labelTitle="Email ID"
                defaultValue={inputdata.CPEmailID}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.CPEmailID && (
                <div className="text-red-500">{errors.CPEmailID}</div>
              )}
              <InputText
                name="address1"
                labelTitle="Address 1"
                defaultValue={inputdata.address1}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.Address1 && (
                <div className="text-red-500">{errors.Address1}</div>
              )}
              <InputText
                name="address2"
                labelTitle="Address 2"
                defaultValue={inputdata.Address2}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.Address2 && (
                <div className="text-red-500">{errors.Address2}</div>
              )}
              <InputText
                name="city"
                labelTitle="City / Locations"
                defaultValue={inputdata.Address3}
                updateFormValue={updateFormValue}
              />

              <Dropdown
                labelTitle="Country"
                name="country"
                // options={countryddl.GetCountries||[]}
                value={selectedOptions.GetCountries}
                onChange={handleDropdownChange}
                options={dropdownData.GetCountries || []}
                labelText={"CountryName"}
                labelValue={"CountryID"}
                // onChange={handleDropdownChange}
                sup
              />
              {errors.GetCountries && (
                <div className="text-red-500">{errors.GetCountries}</div>
              )}

              <Dropdown
                labelTitle="State"
                name="state"
                onChange={handleDropdownChange}
                options={dropdownData.Getstates || []}
                labelText={"StateName"}
                labelValue={"StateID"}
                // value={selectedOptions.state}
                // onChange={handleDropdownChange}
              />

              <InputText
                type="number"
                name="pinCode"
                labelTitle="Pin Code"
                defaultValue={inputdata.PinCode}
                updateFormValue={updateFormValue}
                sup
              />
              {errors.PinCode && (
                <div className="text-red-500">{errors.PinCode}</div>
              )}
              <div className="grid grid-cols-2 float-center mt-6">
                <div>
                  <input
                    name="isActive"
                    type="checkbox"
                    className="mx-4"
                    checked={selectedRowData.IsActive}
                    onChange={(e) =>
                      updateFormValue(e.target.name, e.target.checked)
                    }
                  />
                  isActive
                </div>
                <div>
                  <input
                    name="isDefault"
                    type="checkbox"
                    className="mx-4"
                    checked={selectedRowData.IsDefault}
                    onChange={(e) =>
                      updateFormValue(e.target.name, e.target.checked)
                    }
                  />
                  Is Default Branch For Rate
                </div>
              </div>
              <InputText
                type="number"
                name="serviceTaxNo"
                labelTitle="Service Tax No"
                defaultValue={selectedRowData.ServiceTaxNo}
                updateFormValue={updateFormValue}
              />
              <InputText
                type="number"
                name="salesTaxNo"
                labelTitle="Sales Tax No"
                defaultValue={selectedRowData.SalesTaxNo}
                updateFormValue={updateFormValue}
              />
              <InputText
                name="latitude"
                labelTitle=" Latitude"
                defaultValue={selectedRowData.Latitude}
                updateFormValue={updateFormValue}
              />
              <InputText
                type=""
                name="longitude"
                labelTitle="Longitude"
                defaultValue={selectedRowData.Longitude}
                updateFormValue={updateFormValue}
              />
              <InputText
                name="iExchangeBranchID"
                labelTitle="SW/API Branch ID"
                defaultValue={selectedRowData.IExchangeBranchID}
                updateFormValue={updateFormValue}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between mt-4 gap-4">
            <button onClick={handleBack} className="btn w-full md:w-24">
              Back
            </button>
            <button
              type="submit"
              className="btn  bg-[#0a3b93] badge-primary w-full md:w-24"
            >
              Submit
            </button>
          </div>
        </form>
      {/* </TitleCard> */}
    </div>
  );
};

export default BranchMasterForm;
