import React, { useEffect, useState } from "react";
import InputText from "../Input/InputText";
import Dropdown from "../Input/DropDown";
import axios from "axios";
import { message } from "antd";
import { useAuth } from "../Context/AuthContext.js";
import { useLocation } from "react-router-dom";

import {
  VALIDATION_ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  Messages,
} from "../message.js";

const EntityMasterForm = ({ changeForms, selectedRowData }) => {
  const { userdata } = useAuth();
  const location = useLocation();
  // console.log('location.state representative :',location.state)
  const [inputdata, setInputData] = useState(
    {
      ...selectedRowData,
      IsActive: selectedRowData?.IsActive ?? true,
      CreatedBy: userdata.UserID || "null",
      ModifiedBy: userdata.UserID || "null",
    } || location.state
  );

  const [IsCheckedMarried, setIsCheckedMarried] = useState(false);
  const [IsCheckedTransferred, setIsCheckedTransferred] = useState(false);

  const [IsChecked, setisChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [dropdownData, setDropdownData] = useState([]);
  const [organizationDropdownData, setorganizationDropdownData] = useState([]);
  const [typeDropdownData, setTypeDropdownData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [RelationshipQuotient, setRelationshipQuotient] = useState();
  // console.log("inputdatainputdata ", inputdata);
  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }
  };

  const validateField = (name, value) => {
    // Ensure value is a string
    if (typeof value !== "string") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_ERROR}`,
      }));
      return false;
    }

    if (!value || value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_ERROR}`,
      }));
      return false;
    }

    // Additional specific validations

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateField("Name", inputdata.Name);
    if (!isValid) {
      return;
    }

    try {
      let url, method;

      if (selectedRowData && selectedRowData.EntityID) {
        url = `${process.env.REACT_APP_API_BASE_URL}/UpdateAtlysEntityMaster/${selectedRowData.EntityID}`;
        method = `PUT`;
      } else {
        url = `${process.env.REACT_APP_API_BASE_URL}/AtlysEntityMaster`;
        method = `POST`;
      }

      const response = await axios({
        method: method,
        url: url,
        data: inputdata,
      });

      if (selectedRowData && selectedRowData.EntityID) {
        message.success(Messages.Messages_Successfully_Edited);
      } else {
        message.success(Messages.Messages_Successfully);
        e.target.reset(); // Reset the form after successful submission
      }
      handleBack();
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      message.error(
        "There was an error submitting the form. Please try again later."
      );
    }
  };
  // console.log("AtlysEntityMaster",response)
  const handleBack = () => {
    changeForms();
  };

  const updateFormValue = (name, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [name]: typeof value === "string" ? value : Boolean(value), // Ensure boolean value
    }));

    // Remove validation error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  useEffect(() => {
    fetchData();
    fetchTypeData();
    fetchRelationshipData();
    organizationfetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/GetBindDropdownForCountry`
      );
      setDropdownData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const organizationfetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/organizationsddl`
      );
      console.log("API Response:", response.data); // Log the API response
      setorganizationDropdownData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const fetchRelationshipData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/RelationshipQuotient`
      );
      setRelationshipQuotient(response.data);
      console.log("RelationshipQuotient", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTypeData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/commonmaster`
      );
      setTypeDropdownData(response.data);
    } catch (error) {
      console.error("Error fetching type data:", error);
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
    setInputData((prevData) => ({
      ...prevData,
      [name]: typeof value === "string" ? value : String(value),
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const checkHandler = () => {
    setIsCheckedMarried(!IsCheckedMarried);
  };

  const TransferredChecked = () => {
    setIsCheckedTransferred(!IsCheckedTransferred);
    console.log("clicked checkbox TransferredChecked111111");
  };

  const handleCheckboxChange = (e) => {
    checkHandler();
    updateFormValue(e.target.name, e.target.checked);
  };
  const handleTransferredChecked = (e) => {
    TransferredChecked();
    updateFormValue(e.target.name, e.target.checked);
  };

  const renderUserFields = () => {
    if (IsCheckedMarried || inputdata.IsMarried) {
      return (
        <div className="my-4">
          <InputText
            type="date"
            name={"DateOfAnniversary"}
            value={inputdata.DateOfAnniversary}
            labelTitle={"Anniversary Date "}
            updateFormValue={updateFormValue}
          ></InputText>
        </div>
      );
    }
    return null;
  };

  const IsTransferredfild = () => {
    // console.log("clicked checkbox IsTransferredfild");
    if (IsCheckedTransferred || inputdata.IsTransferred) {
      return (
        <div>
          <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4 ">
            <Dropdown
              name="TransferredCountry"
              labelTitle="Transferred to - Country Name "
              value={inputdata.TransferredCountry}
              updateFormValue={updateFormValue}
              onChange={handleDropdownChange}
              options={dropdownData || []}
              labelText={"CountryName"}
              labelValue={"CountryID"}
              // sup
            />

            <Dropdown
              type=""
              name="Organization"
              labelTitle="Organization"
              value={inputdata.Organization}
              updateFormValue={updateFormValue}
              onChange={handleDropdownChange}
              options={organizationDropdownData || []}
              labelText={"NameOfServiceProvider"}
              labelValue={"OrgID"}
            />
          </div>

          <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4 ">
           
            <InputText
              type="number"
              name="AdditionalContactNo"
              labelTitle="New Contact Number "
              value={inputdata.AdditionalContactNo}
              updateFormValue={updateFormValue}
            />
            <InputText
              type="number"
              name="AdditionalMobileNo"
              labelTitle="New Mobile Number "
              value={inputdata.AdditionalMobileNo}
              updateFormValue={updateFormValue}
            />
          </div>

          <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4 ">
            <InputText
              type="email"
              name="AddiitonalEmailID"
              labelTitle="New e-mail addresss"
              value={inputdata.AddiitonalEmailID}
              updateFormValue={updateFormValue}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="">
          <div className="gap-6">
            <div className="divider"></div>
            <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4 ">
              <div>
                <InputText
                  name="Name"
                  labelTitle="Name of the representative "
                  defaultValue={inputdata.Name}
                  updateFormValue={updateFormValue}
                  sup
                />

                {errors.Name && (
                  <div className="text-red-500  text-sm ">{errors.Name}</div>
                )}
              </div>
              <InputText
                name="Designation"
                labelTitle="Designation"
                defaultValue={inputdata.Designation}
                updateFormValue={updateFormValue}
              />
            </div>

            <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4 ">
              <InputText
                name="LandLineNumber"
                labelTitle="Land line number"
                defaultValue={inputdata.LandLineNumber}
                updateFormValue={updateFormValue}
              />
              <InputText
                name="Phone"
                labelTitle="Mobile Number"
                defaultValue={inputdata.Phone}
                updateFormValue={updateFormValue}
                // sup
              />
              <InputText
                name="SecondaryMobileNo"
                labelTitle=" Secondary Mobile Number"
                defaultValue={inputdata.SecondaryMobileNo}
                updateFormValue={updateFormValue}
                // sup
              />
              <InputText
                type="email"
                name="Email"
                labelTitle="e-mail address"
                value={inputdata.Email}
                updateFormValue={updateFormValue}
                // sup
              />
            </div>

            <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4 ">
              <InputText
                type="email"
                name="SecondaryEmailID"
                labelTitle=" Secondary e-mail address"
                value={inputdata.SecondaryEmailID}
                updateFormValue={updateFormValue}
                // sup
              />
              <InputText
                type="date"
                name="DateOfBirth"
                labelTitle="Birthday"
                value={inputdata.DateOfBirth}
                updateFormValue={updateFormValue}
              />{" "}
            </div>

            <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4 ">
              <div>
                <div className="my-1">
                  <input
                    name="IsMarried"
                    type="checkbox"
                    className="mx-1"
                    defaultChecked={inputdata.IsMarried}
                    onChange={handleCheckboxChange}
                  />
                  Married
                </div>
                {renderUserFields()}
              </div>

              <InputText
                type=""
                name="Geographiesserved "
                labelTitle="Geographies served "
                Value={inputdata.GeographiesServed}
                updateFormValue={updateFormValue}
              />
            </div>

            <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4 ">
              <Dropdown
                name="RelationshipQuotient"
                labelTitle="Relationship Quotient"
                value={inputdata.RelationshipQuotient}
                updateFormValue={updateFormValue}
                onChange={handleDropdownChange}
                options={RelationshipQuotient || []}
                labelText={"CMDescription"}
                labelValue={"CMID"}
              />

              <InputText
                type=""
                name="City"
                labelTitle="City"
                defaultValue={inputdata.City}
                updateFormValue={updateFormValue}
              />
            </div>
          </div>
          <div className="gap-6">
            <div className="divider"></div>
            <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4">
              <Dropdown
                name="Type"
                labelTitle="Type"
                value={inputdata.Type}
                updateFormValue={updateFormValue}
                onChange={handleDropdownChange}
                options={typeDropdownData || []}
                labelText={"CMDescription"}
                labelValue={"CMDescription"}
              />
            </div>
            <div>
              <input
                name="IsTransferred"
                type="checkbox"
                className="mx-1"
                checked={inputdata.IsTransferred}
                onChange={handleTransferredChecked}
              />
              Transferred to (if yes) {IsTransferredfild()}
            </div>

            <div className=" ">
              <main className=" py-3 font-sans">
                <label
                  htmlFor="dropzone-file"
                  className=" cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-gray-600 bg-white p-2 text-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 text-blue-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>

                  <h2 className="mt-4  font-medium text-gray-700 tracking-wide">
                    Business Card
                  </h2>

                  <p className="mt-1 text-gray-500 tracking-wide">
                    Upload or drag & drop your file SVG, PNG, JPG or GIF.
                  </p>

                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    value={inputdata.BusinessCard}
                    onChange={handleFileChange}
                  />

                  {/* Preview */}
                  {previewUrl && (
                    <div className="mt-4">
                      <img
                        src={previewUrl}
                        alt="Preview"
                        className="max-w-full max-h-64 rounded-md border border-gray-300"
                      />
                    </div>
                  )}
                </label>
              </main>
            </div>

            <div className=" my-3 gap-10">
              <div>
                <input
                  name="IsActive"
                  type="checkbox"
                  className="mx-4"
                  checked={inputdata.IsActive === true}
                  onChange={(e) =>
                    updateFormValue(e.target.name, e.target.checked)
                  }
                />
                Is Active
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between mt-4 gap-4">
          <button onClick={handleBack} className="btn w-full md:w-24">
            Back
          </button>
          <button
            type="submit"
            className="btn bg-gray-800 text-white hover:bg-neutral-400 border-none hover:text-black px-6 btn-sm normal-case md:ml-4"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default EntityMasterForm;
