// src/Context/UserContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const UserContext = createContext({
  user: null,
  setUser: () => {},
});

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // State to hold user data

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook for using UserContext
export const useUser = () => useContext(UserContext);

// Note: Do not export UserContext here if you are only using useUser hook.

