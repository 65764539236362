import React, { useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import Pagination from "../components/Pagination";
import TitleCard from "../Card/TitleCard";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDeleteForever } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { useLocation, useParams } from "react-router-dom";
import { decode } from "../utils";
import { message } from "antd";
import { VALIDATION_ERROR_MESSAGES, SUCCESS_MESSAGES , Messages} from "../message.js";
const PageSize = 10;
const secretKey = process.env.REACT_APP_SECRET_KEY;

const Usermastermain = ({ changeForms }) => {
  const [UserMaster, setUserMaster] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [SearchQuery, setSearchquery] = useState("");
  const [rightsarr, setRightsarr] = useState([]);
  const location = useLocation();
  const { id } = useParams();

  // console.log("location usermaster :", location.state);
  // console.log("route usermaster :", id);
  // const decrypt = decode(id);

  // console.log("decryptedData usermaster :", decrypt);

  // const decryptedData = JSON.parse(decrypt.Rights);
  // setRightsarr(decryptedData);

  useEffect(() => {
    if (id) {
      const decrypt = decode(id);
      const decryptedData = JSON.parse(decrypt.Rights);
      setRightsarr(decryptedData);
    }
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/BindGridusermaster_get`
        );
        setUserMaster(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (UserID) => {
    if (UserID === 1) {
      alert("This user cannot be deleted.");
      return;
    }

    try {
      const requestData = JSON.stringify({ UserID });
      const encryptedData = CryptoJS.AES.encrypt(
        requestData,
        secretKey
      ).toString();

      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/Deleteusermaster`,
        {
          data: { encryptedData },
        }
      );

      if (response.status === 200) {
        setUserMaster((prevUserMaster) =>
          prevUserMaster.filter((user) => user.UserID !== UserID)
        );
        setShowModal(false);
      } else {
        console.error(
          "Failed to delete user. Response status:",
          response.status
        );
      }

      message.success(Messages.Messages_Deleted);
    } catch (error) {
      message.error(Messages.Messages_Deleted_Error);
      
    }
  };

  const handleEdit = async (UserID) => {
    try {
      const response = await axios.get(
       `${process.env.REACT_APP_API_BASE_URL}/GetPopulateuserdata/${UserID}`
      );
      const bytes = CryptoJS.AES.decrypt(response.data, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      setSelectedRowData(JSON.parse(decryptedData));
      changeForms(JSON.parse(decryptedData)[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const filteredData = UserMaster.filter(
    (item) =>
      (item.UserID && item.UserID.toString().includes(SearchQuery)) ||
      (item.UserCode &&
        item.UserCode.toLowerCase().includes(SearchQuery.toLowerCase())) ||
      (item.UserName &&
        item.UserName.toLowerCase().includes(SearchQuery.toLowerCase())) ||
      (item.EmailID &&
        item.EmailID.toLowerCase().includes(SearchQuery.toLowerCase())) ||
      (item.ContactNo && item.ContactNo.toString().includes(SearchQuery))
  );
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = filteredData.slice(firstPageIndex, lastPageIndex);

  const openModal = (UserID) => {
    setUserToDelete(UserID);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setUserToDelete(null);
  };

  return (
    <div className="">
      {/* <div className="flex justify-between items-center mb-4"> */}
      <div className="flex flex-col md:flex-row justify-between mb-4 mt-4 gap-3">
        <input
          placeholder="Search......"
          type="text"
          className={` border-slate-300
            "cursor-not-allowed" : "cursor-default"
           rounded-md h-10 p-0 pl-3 border-2   sm:mt-2 w-full md:w-1/2 lg:w-1/3 `}
          value={SearchQuery}
          onChange={(e) => setSearchquery(e.target.value)}
        />
        {rightsarr[1] !== 0 && (
          <button
            className="btn bg-gray-800 text-white hover:bg-neutral-400 border-none hover:text-black px-6 btn-sm normal-case md:ml-4"
            onClick={() => {
              changeForms();
            }}
          >
            <IoIosAdd className="text-white h-6 w-6   hover:text-black " />
            New
          </button>
        )}
      </div>
      <div className="overflow-x-auto w-full ">
        <table className="table w-full mt-5 md:mt-5">
          <thead className="bg-gray-100 text-left">
            <tr className="text-gray-500">
              <th className="text-sm  py-2 px-4">UserID</th>
              <th className="text-sm  py-2 px-4">UserCode</th>
              <th className="text-sm  py-2 px-4">UserName</th>
              <th className="text-sm py-2 px-4">ContactNo</th>
              <th className="text-sm  py-2 px-4">EmailID</th>
              <th className="text-sm py-2 px-4">Action</th>

              {/* <th className="text-sm py-2 px-4">Edit</th>
              <th className="text-sm py-2 px-4">Delete</th> */}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentTableData.map((user) => (
              <tr key={user.UserID}>
                <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {user.UserID}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm">
                  {user.UserCode}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm">
                  {user.UserName}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm">
                  {user.ContactNo}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm">
                  {user.EmailID}
                </td>

                <div className="flex py-4   items-center  gap-8">
                  {rightsarr[2] !== 0 && (
                    <button
                      className="text-green-400  hover:text-green-700 flex   items-center text-center pointer-events-auto "
                      onClick={() => handleEdit(user.UserID)}
                    >
                      <AiOutlineEdit className="h-6 w-6" />
                    </button>
                  )}

                  {rightsarr[0] !== 0 && (
                    <button
                      className=" text-sm text-red-600 cursor-pointer hover:text-red-800"
                      onClick={() => openModal(user.UserID)}
                    >
                      <MdOutlineDeleteForever className="h-6 w-6" />
                    </button>
                  )}
                </div>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          className="pagination-bar mt-4"
          currentPage={currentPage}
          totalCount={UserMaster.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>

      {/* Confirmation Modal */}
      {showModal && (
        <div className="   bg-gray-500  bg-opacity-75 fixed inset-0 flex items-center justify-center z-50">
          {userToDelete != 1 && (
            <div className="bg-white px-6 pt-6 pb-2 rounded shadow-xl border border-slate-300">
              <h2 className="text-lg  text-center  font-bold ">Confirm Deletion</h2>
              <p className="mb-4">Are you sure you want to delete this ?</p> <hr />
              <div className="flex justify-between px-8">
                <button
                  className=" text-red-600 px-4 py-2 rounded mr-2"
                  onClick={() => {
                    if (userToDelete) handleDelete(userToDelete);
                  }}
                >
                  Yes
                </button>
                <button
                  className=" btn-secondary"
                  onClick={closeModal}
                >
                  No
                </button>
              </div>
            </div>
          )}
          {userToDelete == 1 && (
            <div className="bg-white p-6 rounded shadow-xl border border-slate-300">
              <p>You are not allowed to delete this user</p>
              <div className="flex justify-end mt-4">
                <button
                  className="  text-red-700 px-4 py-2 rounded mr-2"
                  onClick={closeModal}
                >
                  Back
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Usermastermain;
