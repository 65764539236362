import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  UsersIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { HiOutlineSearch, HiX } from "react-icons/hi";
import { FaCircle } from "react-icons/fa6";
import { RiSettings4Fill } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import { BsMenuButtonWide } from "react-icons/bs";
import Logo from "../images/atlys1.webp";
import CryptoJS from "crypto-js";
import Dashboardicon from "../Icons/dashboard.png";
import { useAuth } from "../Context/AuthContext";
import { SiAwsorganizations } from "react-icons/si";
import Representativesicon from "../Icons/representative.png";
const secretKey = "DFHFLGFHGOHFWU12345";

const Sidebar = ({ toggleSidebar }) => {
  const [selectedMenus, setSelectedMenus] = useState({});
  const [selectedSubMenus, setSelectedSubMenus] = useState({});
  const [menuData, setMenuData] = useState(null);
  const { userdata } = useAuth();
  const id = userdata?.UserID;
  console.log("userdata useAuth", userdata);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/getsidemainmenu/${id}`
        );
        setMenuData(response.data);
        console.log("menuData sidebar :", response.data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleMenu = (menuID) => {
    setSelectedMenus((prevSelectedMenus) => ({
      ...prevSelectedMenus,
      [menuID]: !prevSelectedMenus[menuID],
    }));
  };

  const toggleSubMenu = (menuID) => {
    setSelectedSubMenus((prevSelectedSubMenus) => ({
      ...prevSelectedSubMenus,
      [menuID]: !prevSelectedSubMenus[menuID],
    }));
  };

  const encrypt = (data) => {
    const stringData = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(
      stringData,
      secretKey
    ).toString();
    return encryptedData
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "~");
  };

  return (
    <div className="w-full md:w-48 sm:w-full  h-full  text-gray-100 flex flex-col">
      {/* Header Section */}
      <div className="flex items-center justify-between py-2 ">
        <img src={Logo} alt="Logo" className="h-16 md:" />
        <button
          onClick={toggleSidebar}
          className="text-black w-8 h-8 rounded-full flex items-center justify-center md:hidden hover:bg-gray-700 focus:outline-none"
        >
          <HiX className="w-5 h-5" />
        </button>
      </div>

      {/* Navigation Menu */}
      <nav className="flex-1 overflow-y-auto mt-4">
        <ul>
          {userdata?.IsSuperUser !== "0" && (
            <li>
              <Link
                to="/Dashboard"
                className="flex items-center p-4 hover:bg-[#ebebeb]"
              >
                <MdOutlineDashboardCustomize className="w-6 h-6 text-black" />
                {/* <img src={Dashboardicon}  className="w-6 h-6"  alt="" /> */}
                <span className="ml-1 text-black text-sm">Dashboard</span>
              </Link>
            </li>
          )}

          <li>
            <Link
              to="/Representatives"
              className="flex items-center p-4 hover:bg-[#ebebeb]"
            >
              <img src={Representativesicon} className="w-6 h-6 text-black" />

              <span className="ml-1 text-black text-sm">Representatives</span>
            </Link>
          </li>
          <li>
            <Link
              to="/Organizationpage"
              className="flex items-center p-4 hover:bg-[#ebebeb]"
            >
              <SiAwsorganizations className="w-6 h-6 text-black" />
              <span className="ml-2 text-black text-sm">Organization</span>
            </Link>
          </li>
          {/* You can add additional menu items here */}
          {menuData &&
            menuData.menuMaster.map((menu) => (
              <li key={menu.MainMenuID}>
                <button
                  className={`w-full flex items-center justify-between px-4 py-3 text-black hover:bg-[#ebebeb] ${
                    selectedMenus[menu.MainMenuID] ? "bg-[#ebebeb]" : ""
                  }`}
                  onClick={() => toggleMenu(menu.MainMenuID)}
                >
                  <div className="flex items-center">
                    <BsMenuButtonWide className="w-6 h-6 inline-block text-black mr-3" />
                    {menu.Description}
                  </div>
                  <ChevronDownIcon
                    className={`w-5 h-5 transition-transform duration-300 ${
                      selectedMenus[menu.MainMenuID]
                        ? "transform rotate-180   "
                        : "  "
                    }`}
                  />
                </button>
                {selectedMenus[menu.MainMenuID] && (
                  <ul className="pl-4 mt-2">
                    {menuData.menuLinkMaster
                      .filter((link) => link.MainMenuID === menu.MainMenuID)
                      .map((menuLink) => (
                        <li key={menuLink.MenuLinksID} className="text-sm ">
                          <button
                            className="w-full flex items-center justify-between md:px-4 md:py-2  text-black hover:bg-[#ebebeb]"
                            onClick={() => toggleSubMenu(menuLink.MenuLinksID)}
                          >
                            <div className="flex items-center">
                              <IoSettingsOutline className="w-4 h-4 mr-2" />
                              {menuLink.Description}
                            </div>
                            <ChevronDownIcon
                              className={`w-5 h-5 transition-transform duration-300 ${
                                selectedSubMenus[menuLink.MenuLinksID]
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </button>
                          {selectedSubMenus[menuLink.MenuLinksID] && (
                            <ul className=" mt-2  ">
                              {menuData.mainMenuMaster
                                .filter(
                                  (item) =>
                                    item.MenuLinksID === menuLink.MenuLinksID &&
                                    item.MainMenuID === menu.MainMenuID
                                )
                                .map((subMenu) => {
                                  const [, values] =
                                    subMenu.PageRights.split("=");
                                  const rightsStringarr = values.split(",");
                                  const rightsarr = rightsStringarr.map(Number);

                                  return (
                                    <li
                                      key={subMenu.MenuLinksID}
                                      className="text-sm"
                                    >
                                      <Link
                                        to={
                                          subMenu.PageToBeCalled ===
                                            "../Masters/RptOrders.aspx" ||
                                          subMenu.PageToBeCalled ===
                                            "../Masters/ReportViewer.aspx"
                                            ? `${
                                                subMenu.PageToBeCalled
                                              }/${encrypt({
                                                description:
                                                  subMenu.Description,
                                                pagetype:
                                                  subMenu.CurrentPageType,
                                                Rights:
                                                  JSON.stringify(rightsarr),
                                              })}`
                                            : `${
                                                subMenu.PageToBeCalled
                                              }/${encrypt({
                                                Rights:
                                                  JSON.stringify(rightsarr),
                                              })}`
                                        }
                                        state={{ subMenu }}
                                        className="block md:pl-6 md:py-2  py-2 text-black hover:bg-gray-200"
                                      >
                                        <FaCircle className="inline-block mb-1 w-2 h-2 mr-1" />
                                        {subMenu.Description}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import {
//   HomeIcon,
//   UsersIcon,
//   BuildingLibraryIcon,
// } from "@heroicons/react/24/outline";
// import { MdOutlineDashboardCustomize } from "react-icons/md";
// import { HiX } from "react-icons/hi";
// import { FaCircle } from "react-icons/fa6";
// import { RiSettings4Fill } from "react-icons/ri";
// import { IoSettingsOutline } from "react-icons/io5";
// import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
// import { BsMenuButtonWide } from "react-icons/bs";
// import Logo from "../images/atlys1.webp";
// import CryptoJS from "crypto-js";
// import { useAuth } from "../Context/AuthContext";

// const secretKey = "DFHFLGFHGOHFWU12345";

// const Sidebar = ({ toggleSidebar }) => {
//   const [selectedMenus, setSelectedMenus] = useState({});
//   const [selectedSubMenus, setSelectedSubMenus] = useState({});
//   const [menuData, setMenuData] = useState(null);
//   const { userdata } = useAuth();
//   const id = userdata?.UserID;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_BASE_URL}/getsidemainmenu/${id}`
//         );
//         setMenuData(response.data);
//       } catch (error) {
//         console.error("Error fetching menu data:", error);
//       }
//     };

//     fetchData();
//   }, [id]);

//   const toggleMenu = (menuID) => {
//     setSelectedMenus((prevSelectedMenus) => ({
//       ...prevSelectedMenus,
//       [menuID]: !prevSelectedMenus[menuID],
//     }));
//   };

//   const toggleSubMenu = (menuID) => {
//     setSelectedSubMenus((prevSelectedSubMenus) => ({
//       ...prevSelectedSubMenus,
//       [menuID]: !prevSelectedSubMenus[menuID],
//     }));
//   };

//   const encrypt = (data) => {
//     const stringData = JSON.stringify(data);
//     const encryptedData = CryptoJS.AES.encrypt(
//       stringData,
//       secretKey
//     ).toString();
//     return encryptedData
//       .replace(/\+/g, "-")
//       .replace(/\//g, "_")
//       .replace(/=/g, "~");
//   };

//   const handleMenuClick = () => {
//     toggleSidebar(); // Close the sidebar
//   };

//   return (
//     <div className="w-full md:w-48 sm:w-full h-full text-gray-100 flex flex-col">
//       <div className="flex items-center justify-between py-2 ">
//         <img src={Logo} alt="Logo" className="h-16 md:" />
//         <button
//           onClick={toggleSidebar}
//           className="text-black w-8 h-8 rounded-full flex items-center justify-center md:hidden hover:bg-gray-700 focus:outline-none"
//         >
//           <HiX className="w-5 h-5" />
//         </button>
//       </div>

//       <nav className="flex-1 overflow-y-auto mt-4">
//         <ul>
//           {userdata?.IsSuperUser !== "0" && (
//             <li>
//               <Link
//                 to="/Dashboard"
//                 className="flex items-center p-4 hover:bg-[#ebebeb]"
//                 onClick={handleMenuClick}
//               >
//                 <MdOutlineDashboardCustomize className="w-6 h-6 text-black" />
//                 <span className="ml-3 text-black text-sm">Dashboard</span>
//               </Link>
//             </li>
//           )}

//           <li>
//             <Link
//               to="/home"
//               className="flex items-center p-4 hover:bg-[#ebebeb]"
//               onClick={handleMenuClick}
//             >
//               <HomeIcon className="w-6 h-6 text-black" />
//               <span className="ml-3 text-black text-sm">Home</span>
//             </Link>
//           </li>

//           {/* {menuData && menuData.menuMaster.map((menu) => (
//             <li key={menu.MainMenuID}>
//               <button
//                 className={`w-full flex items-center justify-between px-4 py-3 text-black hover:bg-[#ebebeb] ${selectedMenus[menu.MainMenuID] ? "bg-[#ebebeb]" : ""}`}
//                 onClick={() => toggleMenu(menu.MainMenuID)}
//               >
//                 <div className="flex items-center">
//                   <BsMenuButtonWide className="w-6 h-6 inline-block text-black mr-3" />
//                   {menu.Description}
//                 </div>
//                 <ChevronDownIcon className={`w-5 h-5 transition-transform duration-300 ${selectedMenus[menu.MainMenuID] ? "transform rotate-180" : ""}`} />
//               </button>
//               {selectedMenus[menu.MainMenuID] && (
//                 <ul className="pl-4 mt-2">
//                   {menuData.menuLinkMaster
//                     .filter((link) => link.MainMenuID === menu.MainMenuID)
//                     .map((menuLink) => (
//                       <li key={menuLink.MenuLinksID} className="text-sm ">
//                         <button
//                           className="w-full flex items-center justify-between md:px-4 md:py-2 text-black hover:bg-[#ebebeb]"
//                           onClick={() => {
//                             toggleSubMenu(menuLink.MenuLinksID);
//                             handleMenuClick(); // Close sidebar on submenu click
//                           }}
//                         >
//                           <div className="flex items-center">
//                             <IoSettingsOutline className="w-4 h-4 mr-2" />
//                             {menuLink.Description}
//                           </div>
//                           <ChevronDownIcon className={`w-5 h-5 transition-transform duration-300 ${selectedSubMenus[menuLink.MenuLinksID] ? "transform rotate-180" : ""}`} />
//                         </button>
//                         {selectedSubMenus[menuLink.MenuLinksID] && (
//                           <ul className="mt-2">
//                             {menuData.mainMenuMaster
//                               .filter(
//                                 (item) =>
//                                   item.MenuLinksID === menuLink.MenuLinksID &&
//                                   item.MainMenuID === menu.MainMenuID
//                               )
//                               .map((subMenu) => {
//                                 const [, values] = subMenu.PageRights.split("=");
//                                 const rightsStringarr = values.split(",");
//                                 const rightsarr = rightsStringarr.map(Number);

//                                 return (
//                                   <li key={subMenu.MenuLinksID} className="text-sm">
//                                     <Link
//                                       to={
//                                         subMenu.PageToBeCalled === "../Masters/RptOrders.aspx" ||
//                                         subMenu.PageToBeCalled === "../Masters/ReportViewer.aspx"
//                                           ? `${subMenu.PageToBeCalled}/${encrypt({
//                                               description: subMenu.Description,
//                                               pagetype: subMenu.CurrentPageType,
//                                               Rights: JSON.stringify(rightsarr),
//                                           })}`
//                                           : `${subMenu.PageToBeCalled}/${encrypt({
//                                               Rights: JSON.stringify(rightsarr),
//                                           })}`
//                                       }
//                                       state={{ subMenu }}
//                                       className="block md:pl-6 md:py-2 py-2 text-black hover:bg-gray-200"
//                                       onClick={handleMenuClick} // Close sidebar on submenu click
//                                     >
//                                       <FaCircle className="inline-block mb-1 w-2 h-2 mr-1" />
//                                       {subMenu.Description}
//                                     </Link>
//                                   </li>
//                                 );
//                               })}
//                           </ul>
//                         )}
//                       </li>
//                     ))}
//                 </ul>
//               )}
//             </li>
//           ))} */}

//           {menuData &&
//             menuData.menuMaster.map((menu) => (
//               <li key={menu.MainMenuID}>
//                 <button
//                   className={`w-full flex items-center justify-between px-4 py-3 text-black hover:bg-[#ebebeb] ${
//                     selectedMenus[menu.MainMenuID] ? "bg-[#ebebeb]" : ""
//                   }`}
//                   onClick={() => toggleMenu(menu.MainMenuID)}
//                 >
//                   <div className="flex items-center">
//                     <BsMenuButtonWide className="w-6 h-6 inline-block text-black mr-3" />
//                     {menu.Description}
//                   </div>
//                   <ChevronDownIcon
//                     className={`w-5 h-5 transition-transform duration-300 ${
//                       selectedMenus[menu.MainMenuID]
//                         ? "transform rotate-180"
//                         : ""
//                     }`}
//                   />
//                 </button>
//                 {selectedMenus[menu.MainMenuID] && (
//                   <ul className="pl-4 mt-2">
//                     {menuData.menuLinkMaster
//                       .filter((link) => link.MainMenuID === menu.MainMenuID)
//                       .map((menuLink) => (
//                         <li key={menuLink.MenuLinksID} className="text-sm">
//                           <button
//                             className="w-full flex items-center justify-between md:px-4 md:py-2 text-black hover:bg-[#ebebeb]"
//                             onClick={() => toggleSubMenu(menuLink.MenuLinksID)} // Keep sidebar open
//                           >
//                             <div className="flex items-center">
//                               <IoSettingsOutline className="w-4 h-4 mr-2" />
//                               {menuLink.Description}
//                             </div>
//                             <ChevronDownIcon
//                               className={`w-5 h-5 transition-transform duration-300 ${
//                                 selectedSubMenus[menuLink.MenuLinksID]
//                                   ? "transform rotate-180"
//                                   : ""
//                               }`}
//                             />
//                           </button>
//                           {selectedSubMenus[menuLink.MenuLinksID] && (
//                             <ul className="mt-2">
//                               {menuData.mainMenuMaster
//                                 .filter(
//                                   (item) =>
//                                     item.MenuLinksID === menuLink.MenuLinksID &&
//                                     item.MainMenuID === menu.MainMenuID
//                                 )
//                                 .map((subMenu) => {
//                                   // Ensure you're using the correct variable name for subMenu
//                                   const [, values] =
//                                     subMenu.PageRights.split("=");
//                                   const rightsStringarr = values.split(",");
//                                   const rightsarr = rightsStringarr.map(Number);

//                                   return (
//                                     <li
//                                       key={subMenu.MenuLinksID}
//                                       className="text-sm"
//                                     >
//                                       <Link
//                                         to={
//                                           subMenu.PageToBeCalled ===
//                                             "../Masters/RptOrders.aspx" ||
//                                           subMenu.PageToBeCalled ===
//                                             "../Masters/ReportViewer.aspx"
//                                             ? `${
//                                                 subMenu.PageToBeCalled
//                                               }/${encrypt({
//                                                 description:
//                                                   subMenu.Description,
//                                                 pagetype:
//                                                   subMenu.CurrentPageType,
//                                                 Rights:
//                                                   JSON.stringify(rightsarr),
//                                               })}`
//                                             : `${
//                                                 subMenu.PageToBeCalled
//                                               }/${encrypt({
//                                                 Rights:
//                                                   JSON.stringify(rightsarr),
//                                               })}`
//                                         }
//                                         state={{ subMenu }}
//                                         className="block md:pl-6 md:py-2 py-2 text-black hover:bg-gray-200"
//                                       >
//                                         <FaCircle className="inline-block mb-1 w-2 h-2 mr-1" />
//                                         {subMenu.Description}
//                                       </Link>
//                                     </li>
//                                   );
//                                 })}
//                             </ul>
//                           )}
//                         </li>
//                       ))}
//                   </ul>
//                 )}
//               </li>
//             ))}
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default Sidebar;
