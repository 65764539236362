import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "../Hook/UsePagination";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
//import "";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={classnames("pagination-container", { [className]: className })}>

      {/* Left navigation arrow */}
      <li
        className={classnames("pagination-item", {disabled: currentPage === 1,})}onClick={onPrevious}>
       <ChevronLeftIcon className="text-gray-800 dark:text-white h-5 w-5" aria-hidden="true" />
      </li>

      {paginationRange.map((pageNumber) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) { 
            // &#8230;
          return <li className="pagination-item dots flex justify-center items-center border border-transparent text-gray-800 hover:bg-gray-100 py-1 px-1 mx-1 sm:py-2 sm:px-3 sm:mx-1 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:border-transparent dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10 ">
            ...</li>;
        }

        // Render our Page Pills
        return (
          <li
            className={`min-h-[38px] flex justify-center items-center border border-transparent text-gray-800 hover:bg-gray-100 py-1 px-1 mx-1 sm:py-2 sm:px-3 sm:mx-1 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:border-transparent dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10${classnames("pagination-item", {selected: pageNumber === currentPage,})}`}
            onClick={() => onPageChange(pageNumber)} >
            {pageNumber}
          </li>
        );
      })}

      {/*  Right Navigation arrow */}
      <li
        className={` ${classnames("pagination-item", {disabled: currentPage === lastPage,})}`}
        onClick={onNext}>
        {/* <div className="arrow right" /> */}
        <ChevronRightIcon className="text-gray-800 dark:text-white h-5 w-5 md:w-5 md:h-5" aria-hidden="true" />
      </li>
    </ul>
  );
};

export default Pagination;