
import React, { useState } from "react";
// import Brachmastermain from "../../Component/MastersMain/Brachmastermain";
// import BranchMasterForm from "../../Component/MasterForms/BranchMasterForm";
import BranchMasterForm from "../MasterFrom/BranchMasterForm";
import Branchmastermain from "../Mastermains/Branchmastermain";
import TitleCard from "../Card/TitleCard";

function BranchMaster() {
  const [showBilling, setShowBilling] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const EmptData = {
    companyID: "",
    branchCode: "",
    branchName: "",
    branchEmail: "",
    stdCode: "",
    telNo1: "",
    telNo2: "",
    faxNo1: "",
    faxNo2: "",
    panNo: "",
    registrationNo: "",
    vatNo: "",
    tinNo: "",
    companyName: "",
    officeTimeTable: "",
    cpName: "",
    cpMobileNo: "",
    cpEmailID: "",
    address1: "",
    address2: "",
    city: "",
    countryID: "",
    stateID: "",
    pinCode: "",
    serviceTaxNo: "",
    salesTaxNo: "",
    latitude: "",
    longitude: "",
    iExchangeBranchID: "",
  };

  // To Chnage form
  const changeForms = (rowData) => {
    setSelectedRowData(rowData ? rowData : EmptData);
    setShowBilling(!showBilling);
  };

  return (
    <TitleCard title={'Branch Master'}>
      {showBilling ? (
        <Branchmastermain changeForms={changeForms} /> ) : (
        <BranchMasterForm changeForms={changeForms}selectedRowData={selectedRowData} />
      )}
    </TitleCard>
  );
}

export default BranchMaster;

