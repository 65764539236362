import React, { useState, useEffect, memo } from "react";
import axios from "axios";
import Dropdown from "../Input/DropDown";
import { Modal } from "react-responsive-modal";
import "../ModalCSS.css";
import CheckboxGrid from "../Card/CheckboxGrid";

const RightsModal = ({ open, onCloseModal, UserID }) => {
  const [selectedMainMenuID, setSelectedMainMenuID] = useState({
    Firstlvl: 0,
    Secondlvl: 0,
  });
  const [dropdownOptions, setDropdownOptions] = useState({
    FirstLvlddl: [],
    SecondLvlddl: [],
  });
  const [secondlvlgrid, setSecondlvlgrid] = useState([]);
  const [show, setShow] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false); // New state for fetch trigger

  // Fetch First Level DropDown options
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_FirstLvlddl`)
      .then((res) => {
        setDropdownOptions((prevData) => ({
          ...prevData,
          FirstLvlddl: res.data,
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Fetch Second Level DropDown options based on selected First Level
  useEffect(() => {
    if (selectedMainMenuID.Firstlvl !== 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_SecondLvlddl/${selectedMainMenuID.Firstlvl}`
        )
        .then((res) => {
          setDropdownOptions((prevData) => ({
            ...prevData,
            SecondLvlddl: res.data,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedMainMenuID.Firstlvl]);

  // Fetch grid data based on selected dropdown values
  useEffect(() => {
    if (
      selectedMainMenuID.Firstlvl !== 0 &&
      selectedMainMenuID.Secondlvl !== 0
    ) {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_SecondlvlGrid/${selectedMainMenuID.Firstlvl}/${selectedMainMenuID.Secondlvl}/${UserID}`
        )
        .then((res) => {
          setSecondlvlgrid(res.data);
          setShow(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedMainMenuID, fetchTrigger]); // Add fetchTrigger to dependency array

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setSelectedMainMenuID((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const tableHeaders =
    secondlvlgrid.length > 0 ? Object.keys(secondlvlgrid[0]) : [];

    console.log('UserID rightsmodal:',UserID)

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      classNames={{
        modal: "customModal",
      }}
      center
    >
      <div className="h-60">
        <div className="mt-4 font-medium text-[#b83392]">
          <label>Portal setup</label>
        </div>
        <div className="mt-4">
          <Dropdown
            labelTitle="First Level"
            name="Firstlvl"
            options={dropdownOptions.FirstLvlddl}
            labelText={"Description"}
            labelValue={"MainMenuID"}
            onChange={handleDropdownChange}
          />
          <Dropdown
            labelTitle="Second Level"
            name="Secondlvl"
            options={dropdownOptions.SecondLvlddl}
            labelText={"Description"}
            labelValue={"MenuLinksID"}
            onChange={handleDropdownChange}
          />
        </div>
        <div>
          {show ? (
            <CheckboxGrid
              tableHeaders={tableHeaders}
              grid={secondlvlgrid}
              UserID={UserID}
              MainmenuId={selectedMainMenuID.Firstlvl}
              MainmenuLinkId={selectedMainMenuID.Secondlvl}
              onFetchTrigger={() => setFetchTrigger((prev) => !prev)} // Pass callback to CheckboxGrid
            />
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default RightsModal;