import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import TitleCard from "./TitleCard";
import Pagination from "../components/Pagination";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PageSize = 10;
const Linkpage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { EntityID } = useParams(); // Extract EntityID from URL
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Add this line
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("EntityID:", EntityID); // Log EntityID for debugging
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/representativeslink/${EntityID}`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        console.log("API Response:", result); // Log the full API response
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [EntityID]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = data.slice(firstPageIndex, lastPageIndex);
  return (
    <TitleCard>
      <div className="overflow-x-auto">
        <h1 className=" md:text-1xl font-bold text-gray-800">Organization</h1>
        <table className="table w-full mt-2 md:mt-4 lg:mt-4">
          <thead className="bg-gray-100 text-left">
            <tr className="text-gray-500 justify-between ">
              <th className="text-xs md:text-sm py-2 px-4">Source Country</th>
              <th className="text-xs md:text-sm py-2 px-4">Service Provider</th>
              <th className="text-xs md:text-sm py-2 px-4">City</th>
              <th className="text-xs md:text-sm py-2 px-4">Client Type</th>
              <th className="text-xs md:text-sm py-2 px-4">Client Country</th>
              <th className="text-xs md:text-sm py-2 px-4 ">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentTableData.map((org, k) => (
              <tr className="text-left" key={k}>
                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm flex gap-4">
                  {org.SourceCountry}{" "}
                </td>

                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                  {org.NameOfServiceProvider}
                </td>
                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                  {org.City}
                </td>
                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                  {org.ClientType}
                </td>
                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                  {org.ClientCountry}
                </td>
                <td className="p-2 whitespace-nowrap px-8 ">
                  <td className="p-2 whitespace-nowrap  ">
                    <button
                      className="text-blue-500"
                      onClick={() =>
                        navigate(`/Organizationpage/OrgCardpage`, {
                          state: { orgId: org.OrgID },
                        })
                      }
                    >
                      <FaEye />
                    </button>
                  </td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          className="pagination-bar mt-4"
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </TitleCard>
  );
};

export default Linkpage;
