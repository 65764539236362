import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import "../ModalCSS.css"; // Ensure this import is correct


const LogonHoursModal = ({ open, onCloseModal, GroupID }) => {
  const [isCheckAll, setIsCheckAll] = useState({
    IsSunday: false,
    IsMonday: false,
    IsTuesday: false,
    IsWednesday: false,
    IsThursday: false,
    IsFriday: false,
    IsSaturday: false
  });

  const [list, setList] = useState([]);
  console.log("GroupID: ", GroupID);

  useEffect(() => {
    console.log("GroupID inside UseEffect: ", GroupID);
    if (GroupID) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_HourGrid/${GroupID}`)
        .then((res) => {
          setList(res.data);
          console.log("HourGrid data: ",res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [GroupID]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Clicked Submit");
  //   const updatedList = list.map(item => ({
  //     ...item,
  //     UserID: GroupID
  //   }));
  //   // Make your API call here to update the data with updatedList
  //   console.log("Updated List: ", updatedList);
  //   // console.log("list : ", list)
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const updatedList = list.map(item => ({
      ...item,
      UserID: GroupID
    }));
    console.log('Updated List:', updatedList); // Add this line to check the data structure
  
    try {
      const response = await fetch('${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_LogonHourUpdate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          GroupID: GroupID,
          logonHours: updatedList
        })
      });
  
      if (response.ok) {
        console.log('Logon hours updated successfully');
      } else {
        console.error('Failed to update logon hours');
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const handleSelectAll = (column) => {
    const newIsCheckAll = { ...isCheckAll, [column]: !isCheckAll[column] };
    setIsCheckAll(newIsCheckAll);
    const newList = list.map(row => ({
      ...row,
      [column]: newIsCheckAll[column]
    }));
    setList(newList);
  };

  const handleClick = (e, index, column) => {
    const newList = [...list];
    newList[index][column] = e.target.checked;
    setList(newList);
    // console.log(list)
  };

  const days=['IsSunday', 'IsMonday', 'IsTuesday', 'IsWednesday', 'IsThursday', 'IsFriday', 'IsSaturday']

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        classNames={{
          modal: "customModal",
        }}
        center
      >
        <div className="h-100">
          <div className="mt-4 font-medium text-[#b83392]">
            <label>Portal User - Logon Hours</label>
          </div>
          <form onSubmit={handleSubmit}>
            <table className="table w-full mt-5 md:mt-5">
              <thead className="text-center text-sm">
                <tr>
                  <th>Hours</th>
                  {days.map((day, idx) => (
                    <th key={idx}>
                      <input
                        type="checkbox"
                        onChange={() => handleSelectAll(day)}
                        checked={isCheckAll[day]}
                      /> {day.replace('Is', '')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {list.map((row, index) => (
                  <tr key={index} className="text-center">
                    <td>{row.Hours}</td>
                    {days.map((day, idx) => (
                      <td key={idx}>
                        <input
                          type="checkbox"
                          checked={row[day]}
                          onChange={(e) => handleClick(e, index, day)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="float-end mt-8">
              <button type="submit"   className=" p-2 rounded  bg-gray-800 text-white hover:bg-neutral-400 border-none hover:text-black px-6 btn-sm normal-case md:ml-4">
                Update
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default LogonHoursModal;

