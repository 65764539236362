import React, { useState, useEffect } from "react";
import axios from "axios";
// import Pagination from "../Pagination/Pagination";
// import TitleCard from "../Cards/TitleCard";
import CryptoJS from "crypto-js";
const PageSize = 10;
const secretKey = "DFHFLGFHGOHFWU12345";

function Brachmastermain({ changeForms }) {
  const [BranchMaster, setBranchMaster] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [SearchQuery, setSearchquery] = useState("");
  const handleEdit = async (BranchID, changeForms) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/PopulateData/${BranchID}`)
        .then((res) => {
          setSelectedRowData(res.data);
          changeForms(res.data[0]); // Pass res.data to changeForms
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Get Data From api to show in table

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/records`).then((res) => {
      // const Decrypt = CryptoJS.AES.decrypt(res.data,"DFHFLGFHGOHFWU12345");
      // const decryptedData = Decrypt.toString(CryptoJS.enc.Utf8);
      // setBranchMaster(JSON.parse(decryptedData));
      // console.log(JSON.parse(decryptedData));
      // console.log("branch",decryptedData)
      setBranchMaster(res.data);
    });
  }, []);

  // Pagination

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = BranchMaster.slice(firstPageIndex, lastPageIndex);

  const handleDelete = async (branchID, userID) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/BranchMasterDelete`,
        {
          data: { BranchID: branchID, userID: userID },
        }
      );
      setBranchMaster(
        BranchMaster.filter((branch) => branch.BranchID !== branchID)
      );
    } catch (error) {
      console.error("Error deleting branch:", error);
    }
  };

  const ChangeBranchMasterForm = () => {
    changeForms();
  };

  //  const  fillteredData = BranchMaster.filter(
  //   (item)=>
  //   (item.BranchID && item.BranchID.toString().includes(SearchQuery)) ||
  //   (item.CompanyName && item.CompanyName.toString().includes(SearchQuery))||
  //   (item.BranchCode && toLowerCase().includes(SearchQuery.toLowerCase()))||
  //   (item.BranchName && item.BranchName.toString().includes(SearchQuery))||
  //   (item.BranchEmail && item.BranchEmail.toString().includes(SearchQuery))||
  //   (item.CPName && item.CPName.toString().includes(SearchQuery))||
  //   (item.CPMobileNo && item.CPMobileNo.toString().includes(SearchQuery))||
  //  )

  return (
    <>
      <div className="py-3">
        {/* <TitleCard title="Branch Master" topMargin="mt-2"> */}
          <div className="flex flex-col md:flex-row justify-between mb-4">
            <input
              placeholder="Search......"
              type="text"
              className="input input-bordered w-full md:w-1/2 lg:w-1/3 mb-2 md:mb-0"
              value={SearchQuery}
              onChange={(e) => setSearchquery(e.target.value)}
            />

            <button
              className="btn bg-[#0a3b93] text-white hover:text-black px-6 btn-sm normal-case md:ml-4"
              onClick={ChangeBranchMasterForm}
            >
              ADD New
            </button>
          </div>

          {/* Invoice list in table format loaded constant */}
          <div className="overflow-x-auto ">
            <table className="table mx-4 w-full mt-5 bg-white">
              <thead className="text-center">
                <th className="text-black py-2">BranchID</th>
                <th className="text-black py-2">CompanyName</th>
                <th className="text-black py-2">BranchCode</th>
                <th className="text-black py-2">BranchName</th>
                <th className="text-black py-2">BranchEmail</th>
                <th className="text-black py-2">CPName</th>
                <th className="text-black py-2">CPMobileNo</th>
                <th className="text-sm text-green-400 ">Edit</th>
                <th className="text-sm text-red-500">Delete</th>
              </thead>
              <tbody>
                {currentTableData.map((branch, k) => {
                  return (
                    <tr className="text-center" key={k}>
                      <td>{branch.BranchID}</td>
                      <td>{branch.CompanyName}</td>
                      <td>{branch.BranchCode}</td>
                      <td>{branch.BranchName}</td>
                      <td>{branch.BranchEmail}</td>
                      <td>{branch.CPName}</td>
                      <td>{branch.CPMobileNo}</td>
                      <td>
                        <button
                          className="text-green-400 pointer-events-auto"
                          onClick={() => {
                            handleEdit(branch.BranchID, changeForms);
                          }}
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            handleDelete(branch.BranchID, branch.userID)
                          }
                          className="text-red-500"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <Pagination
            className="pagination-bar mt-4"
            currentPage={currentPage}
            totalCount={BranchMaster.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          /> */}
        {/* </TitleCard> */}
      </div>
    </>
  );
}
export default Brachmastermain;
