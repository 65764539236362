import React,{useState} from "react";
import TitleCard from "../Card/TitleCard";
import RoleMasterForm from "../MasterFrom/RoleMasterForm";
import RoleMastermain from "../Mastermains/RoleMastermain";

const UserMasterGroup=()=>{
    const [ShowContainer, setShowContainer] = useState(true);
    const [selectedRowData, setSelectedRowData] = useState(null);
  
    const EmptData = {
      GroupID:0,
      UserCode:"",
      UserName:"",
      IsActive:null,
    };
  
    // To Chnage form
    const changeForms = (rowData) => {
      setSelectedRowData(rowData ? rowData : EmptData);
      // console.log('rowData :',rowData);
      setShowContainer(!ShowContainer);
    };
  
    return (
      <div>
        <TitleCard title="Role Master">
          {ShowContainer ? (
            <RoleMastermain changeForms={changeForms} />
          ) : (
            <RoleMasterForm
              changeForms={changeForms}
              selectedRowData={selectedRowData}
            />
          )}
        </TitleCard>
      </div>
    );
}

export default UserMasterGroup;