import React, { useEffect, useState } from "react";
import InputText from "../Input/InputText";
import axios from "axios";
import Dropdown from "../Input/DropDown";
//import { VALIDATION_ERROR_MESSAGES } from "../message.js";
import CryptoJS from "crypto-js";
import { message } from "antd";
import {
  VALIDATION_ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  Messages,
} from "../message.js";
const secretKey = process.env.REACT_APP_SECRET_KEY;
const UsermasterForm = ({ changeForms, selectedRowData }) => {
  const [inputdata, setInputData] = useState({
    ...selectedRowData,
    IsActive: selectedRowData?.IsActive ?? true,
  });
  const [dropdownData, setDropdownData] = useState({});
  const [errors, setErrors] = useState({});

  const UserCategorydrop = [
    { Text: "Internal", Value: "I" },
    { Text: "External", Value: "E" },
  ];

  const [fileInput, setFileInput] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }
  };

  const validateField = (name, value) => {
    // Ensure value is a string
    if (typeof value !== "string") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_ERROR}`,
      }));
      return false;
    }

    if (!value || value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${VALIDATION_ERROR_MESSAGES.VALIDATION_ERROR}`,
      }));
      return false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    return true;
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setFileInput(file);
  };

  const updateFileFormValue = (name, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid =
      validateField("UserCode", inputdata.UserCode) &
      validateField("UserName", inputdata.UserName) &
      validateField("Password", inputdata.Password) &
      validateField("ContactNo", inputdata.ContactNo) &
      validateField("EmailID", inputdata.EmailID);

    if (!isValid) {
      return;
    }
    console.log("SUBMIT DATA", inputdata);

    // const customIV = CryptoJS.lib.WordArray.random(16); // 16 bytes IV for AES encryption
    // const encryptedData = CryptoJS.AES.encrypt(
    //   JSON.stringify(inputdata),
    //   secretKey,
    //   { iv: customIV } // Replace customIV with your actual IV
    // ).toString();

   // try {
      // let url;
      // let method;

      // if (selectedRowData && selectedRowData.UserID) {
      //   url = `${process.env.REACT_APP_API_BASE_URL}/UserUpdate${selectedRowData.UserID}`;
      //   method = "PUT";
      // } else {
      //   url = `${process.env.REACT_APP_API_BASE_URL}/Usermastersubmit`;
      //   method = "POST";
      // }

      // const response = await axios({
      //   method: method,
      //   url: url,
      //   data: inputdata,
      // });



      try {
        let url, method;
  
        if (selectedRowData && selectedRowData.UserID) {
          url = `${process.env.REACT_APP_API_BASE_URL}/UserUpdate/${selectedRowData.UserID}`;
          method = `PUT`;
        } else {
          url = `${process.env.REACT_APP_API_BASE_URL}/Usermastersubmit`;
          method = `POST`;
        }
  
        const response = await axios({
          method: method,
          url: url,
          data: inputdata,
        });


      



      if (response.status === 200) {
        message.success(
          selectedRowData && selectedRowData.UserID
            ? Messages.Messages_Successfully_Edited
            : Messages.Messages_Successfully
        );
        changeForms();
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      message.error(Messages.Messages_submit_error);
    }
    
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/BinddropEmployeeBranchddl`
      );
      const decryptedBytes = CryptoJS.AES.decrypt(response.data, secretKey);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      const dropdata = JSON.parse(decryptedData);
      setDropdownData(dropdata);
      console.log("Decrypted dropdata :", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateFormValue = (name, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Remove validation error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };
  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    // console.log("name:", name, " value:", value);
    // setInputData((prevOptions) => ({
    //   ...prevOptions,
    //   [name]: value,
    // }));

    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Remove validation error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };
  const handleBack = () => {
    changeForms();
  };

  return (
    <div className="mt-4">
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1">
          <div className="gap-6">
            <div className="font-semibold text-lg">General Information</div>
            <div className="divider"></div>
            <InputText
              name="UserCode"
              labelTitle="User Code"
              defaultValue={inputdata.UserCode}
              updateFormValue={updateFormValue}
              sup
            />
            {errors.UserCode && (
              <div className="text-red-500  text-sm">{errors.UserCode}</div>
            )}
            <InputText
              name="UserName"
              // type="text"
              labelTitle="User Name"
              defaultValue={inputdata.UserName}
              updateFormValue={updateFormValue}
              sup
            />
            {errors.UserName && (
              <div className="text-red-500 text-sm">{errors.UserName}</div>
            )}
            <InputText
              name="Password"
              labelTitle="User Password"
              defaultValue={inputdata.Password}
              updateFormValue={updateFormValue}
              sup
            />
            {errors.Password && (
              <div className="text-red-500 text-sm">{errors.Password}</div>
            )}
            <InputText
              type="number"
              name="ContactNo"
              labelTitle="Contact No."
              defaultValue={inputdata.ContactNo}
              updateFormValue={updateFormValue}
              sup
            />
            {errors.ContactNo && (
              <div className="text-red-500 text-sm">{errors.ContactNo}</div>
            )}

            <InputText
              type="EmailID"
              name="EmailID"
              // labelTitle="Email ID (Also Add Multiple with using ' , ')"
              labelTitle="Email ID "
              defaultValue={inputdata.EmailID}
              updateFormValue={updateFormValue}
              sup
              multiple
            />
            {errors.EmailID && (
              <div className="text-red-500 text-sm">{errors.EmailID}</div>
            )}
            <div>
              <input
                name="IsActive"
                type="checkbox"
                className="mx-4"
                checked={inputdata.IsActive}
                onChange={(e) =>
                  updateFormValue(e.target.name, e.target.checked)
                }
              />
              Is Active
            </div>
            <div className="">
              <input
                name="IsSuperUser"
                type="checkbox"
                className="mx-4 "
                checked={inputdata.IsSuperUser}
                onChange={(e) =>
                  updateFormValue(e.target.name, e.target.checked)
                }
              />
              Is Super User
            </div>
          </div>
          {/* Address Information */}
          <div className="gap-6 ">
            <div className="font-semibold text-lg">Account Information</div>
            <div className="divider"></div>
            <Dropdown
              name="GroupID"
              labelTitle="Group"
              options={dropdownData.Getusercode || []}
              value={inputdata.GroupID}
              onChange={handleDropdownChange}
              updateFormValue={updateFormValue}
            />
            {errors.GroupID && (
              <div className="text-red-500 text-sm">{errors.GroupID}</div>
            )}
            <Dropdown
              name="UserCategory"
              labelTitle="User Category"
              options={UserCategorydrop}
              value={inputdata.UserCategory}
              onChange={handleDropdownChange}
              updateFormValue={updateFormValue}
              labelText="Text"
              labelValue="Value"
            />
            {errors.UserCategory && (
              <div className="text-red-500 text-sm">{errors.UserCategory}</div>
            )}

            <main className="flex items-center justify-center py-3 font-sans">
              <label
                htmlFor="dropzone-file"
                className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-gray-600 bg-white p-6 text-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 text-blue-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>

                <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">
                  Profile Image
                </h2>

                <p className="mt-2 text-gray-500 tracking-wide">
                  Upload or drag & drop your file SVG, PNG, JPG or GIF.
                </p>

                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                />

                {/* Preview */}
                {previewUrl && (
                  <div className="mt-4">
                    <img
                      src={previewUrl}
                      alt="Preview"
                      className="max-w-full max-h-64 rounded-md border border-gray-300"
                    />
                  </div>
                )}
              </label>
            </main>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between mt-4 gap-4">
          <button onClick={handleBack} className="btn w-full md:w-24">
            Back
          </button>
          <button
            type="submit"
            className="btn bg-gray-800 text-white hover:bg-neutral-400 border-none hover:text-black px-6 btn-sm normal-case md:ml-4"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default UsermasterForm;
