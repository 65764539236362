import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../Context/UserContext";
import { useAuth } from "../Context/AuthContext";
import { NavLink } from "react-router-dom";
import notifiction from "../Icons/message.png";
const Notification = () => {
  const { user } = useUser(); // Get user data from context
  const { userdata } = useAuth();

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputData, setInputData] = useState({
    UserID: userdata?.UserID || "",
  });

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/AtlysNotificationData?UserID=${inputData.UserID}`
        );
        if (response.data.success) {
          setNotifications(response.data.data); // Set notifications data
        } else {
          setError("Failed to fetch notifications.");
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [inputData.UserID]);

  if (loading)
    return <div className="text-center text-gray-500">Loading...</div>;
  if (error)
    return <div className="text-center text-red-500">Error: {error}</div>;

  return (
    // <div className="container mx-auto p-2">
    //   <h1 className=" font-bold mb-2">Notifications</h1>

    //   <div className="max-h-[400px] overflow-y-auto  border-gray-300 rounded-lg   p-4">
    //     <ul className="space-y-4">
    //       {notifications.map((notification) => (
    //         <li key={notification.SysID} className="bg-white p-4 border flex justify-between gap-2 rounded-lg shadow-sm">

    //           <div>
    //           <img width="30" height="30" src="https://img.icons8.com/ios/50/envelope-dots.png" alt="envelope-dots"/>
    //           </div>
    //          <div>
    //          <div className="text-gray-500 text-sm mb-1">
    //           {notification.Entity}
    //           </div>
    //           <div className="text-gray-800 text-sm font-semibold mb-1">
    //             {notification.Remarks}
    //           </div>
    //           <div className="text-gray-400 text-xs text-right">
    //             {new Date(notification.NotificationDateTime).toLocaleDateString()}
    //           </div>
    //          </div>
    //         </li>
    //       ))}
    //     </ul>
    //   </div>
    //   <NavLink to='/seemorenofication' className="text-blue-400  text-sm py-2 hover:text-blue-600 " >See More... </NavLink>
    // </div>

    <div className="container mx-auto  md:p-2  p-2">
      <h1 className="font-bold mb-2 text-lg md:text-xl  text-gray-600">
        Notifications
      </h1>

      <div className="max-h-[400px] overflow-y-auto border border-gray-300 rounded-lg p-4">
        <ul className="space-y-4">
          {notifications.map((notification) => (
            <li
              key={notification.SysID}
              className=" p-4 border border-blue-200 flex justify-between gap-4 rounded-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="flex-shrink-0">
                {/* <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios/50/envelope-dots.png"
                alt="envelope-dots"
              /> */}

                <img src={notifiction} alt="" width="40" height="40" />
              </div>
              <div className="flex-1">
                <div className="text-blue-600 text-sm mb-1">
                  {notification.Entity}
                </div>
                <div className="text-gray-800 text-sm  mb-1 md:overflow-hidden">
                  {notification.Remarks}
                </div>
                <div className="text-gray-400 text-xs text-right">
                  {new Date(
                    notification.NotificationDateTime
                  ).toLocaleDateString()}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <NavLink
        to="/seemorenofication"
        className="text-blue-500 text-sm py-2 hover:text-blue-700"
      >
        See More...
      </NavLink>
    </div>
  );
};

export default Notification;
