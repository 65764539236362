// messages.js

export const SUCCESS_MESSAGES = {
    SUBMIT_SUCCESS: 'Successfully submitted!',
    UPDATE_SUCCESS: 'Successfully updated!',
    DELETE_SUCCESS: 'Successfully deleted!',
    // Add more as needed
};

export const ERROR_MESSAGES = {
    NETWORK_ERROR: 'Network error. Please try again later.',
    VALIDATION_ERROR: 'Validation failed. Please check your inputs.',
    SERVER_ERROR: 'Server error. Please try again later.',
    // Add more as needed
};
    
export const  VALIDATION_ERROR_MESSAGES = {
   
    VALIDATION_ERROR: 'This field is required.',
    VALIDATION_EMAIL:"Invalid email format",
    VALIDATION_CONTACT:"Invalid 10-digit phone number",
    VALIDATION_PINNO:"Invalid Pin Number",
    VALIDATION_PANNO:"Invalid pan Card Number",
    VALIDATION_Description:"There was an error submitting the form. Please try again later.",
    VALIDATION_CODE:"There was an error submitting the form. Please try again later.",
    VALIDATION_Priority :"There was an error submitting the form. Please try again later",
    VALIDATION_NAME:"There was an error submitting the form. Please try again later",
    VALIDATION_NUMBRE:"There was an error submitting the form. Please try again later",
    VALIDATION_BANK:"There was an error submitting the form. Please try again later"
};
   
export const Messages = {
    Messages_Successfully: "Successfully submitted!",
    Messages_Successfully_Edited: "Successfully Edited!",
    Messages_Deleted:"Deleted successfully",
    Messages_Deleted_Error:"Failed to delete Representative",
    Messages_submit_error  :" There was an error submitting the form. Please try again later. ",
    Messages_Meeting_Successfully_Edited  :"Meeting schedule updated successfully!",
    Messages_Meeting_Successfully :"Meeting schedule created successfully!",
    Messages_Meeting_Error :"There was an error submitting the form. Please try again.",
    Messages_Note:"Note created successfully!",
   
  };
  