import React from "react";
import Select from "react-select";

const AutocompleteDropdown = ({
  options,
  onChange,
  defaultValue,
  labelTitle,
  labelValue,
  labelText,
  readonly,
}) => {
  return (
    <div>
      <label className="label">
        <span className={"label-text text-base-content font-medium "}>
          <div className="text-sm text-gray-500 ">{labelTitle}</div>
        </span>
      </label>
      <Select
       
        options={options}
        onChange={onChange}
        defaultValue={defaultValue}
        isClearable
        isSearchable
        isMulti // Enable multi-select
        placeholder={`Select ${labelTitle}...`}
        getOptionLabel={(option) => option[labelText]} // Using labelText for dynamic key
        getOptionValue={(option) => option[labelValue]} // Using labelValue for dynamic key
      />
    </div>
  );
};

export default AutocompleteDropdown;
