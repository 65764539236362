
import React, { useState, useEffect } from "react";
import axios from "axios";
import InputText from "../Input/InputText";
import Dropdown from "../Input/DropDown";
import CryptoJS from "crypto-js";
import { message } from "antd";
import { VALIDATION_ERROR_MESSAGES, SUCCESS_MESSAGES , Messages} from "../message.js";
// import { VALIDATION_ERROR_MESSAGES } from "../../messages.js";
// import { NameRegex, CodeValidationRegex } from "../../Regex.js";
const secretKey = process.env.REACT_APP_SECRET_KEY;

const UserMasterGroupForm = ({ changeForms, selectedRowData }) => {
  const [inputdata, setInputData] = useState(
    selectedRowData || { GroupID: "0" }
  );
  const [DropDown, setDropDown] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [valdtCode, setValdtCode] = useState(false);

  console.log(selectedRowData);
  const [errors, setErrors] = useState({}); // Validation errors state

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("FormData: ", inputdata);
    console.log("Edit-GroupID", selectedRowData.UserID);
    // const isValid =
    //   validateField("UserCode", inputdata.UserCode) &
    //   validateField("UserName", inputdata.UserName);

    // if (!isValid) {
    //   // If any field is invalid, prevent form submission
    //   return;
    // }

    //const secretKey = "DFHFLGFHGOHFWU12345";
    const customIV = CryptoJS.lib.WordArray.random(16); // 16 bytes IV for AES encryption
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(inputdata),
      secretKey,
      { iv: customIV } // Replace customIV with your actual IV
    ).toString();
    console.log("entity", inputdata);

    try {
      if (!inputdata) {
        console.error("Error: inputdata is empty");
        return;
      }
      // Await the result of ValidateCode
      const Code = await ValidateCode();
      console.log("Code : ", Code);
      if (Code) {
        alert("Group already Exists");
      } else {
        let url, method;

        if (selectedRowData.UserID == 0 || selectedRowData.GroupID == 0) {
          // Adding new data
          url = `${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_Submit`;
          method = "POST";
        } else {
          // Editing existing data
          url = `${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_Update`;
          method = "POST";
        }

        const response = await axios({
          method: method,
          url: url,
          data: { encryptedData },
        });

        if (response.status === 200) {
          if (selectedRowData.UserID == 0 || selectedRowData.GroupID == 0) {
            message.success(Messages.Messages_Successfully);
            changeForms();
          } else {
            message.success(Messages.Messages_Successfully_Edited);
            changeForms();
          }
        } else {
          console.error("Error: Unexpected status code", response.status);
        }
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      message.error(Messages.Messages_submit_error)
    }
  };

  const ValidateCode = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_GetvalidateusergroupCode?UserCode=${inputdata.UserCode}`
      );
      return inputdata.UserCode == response.data[0].UserCode;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleBack = () => {
    changeForms();
  };

  const updateFormValue = (name, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Remove validation error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    console.log("name: ", name, "value: ", value);
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/EmployeeMaster_ddl`)
      .then((res) => {
        const ddldata = {
          Language: res.data.Language,
          BranchMaster: res.data.BranchMaster,
          UserGroup: res.data.UserGroup,
          EmployeeStatus: res.data.EmployeeStatus,
          Designation: res.data.Designation,
          Location: res.data.Location,
        };
        setDropDown(ddldata);
        console.log(ddldata);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const renderUserFields = () => {
    if (isChecked || selectedRowData.IsUser) {
      return (
        <div>
          <Dropdown
            labelTitle="User Group"
            name="User Group"
            options={DropDown.UserGroup || []}
            checked={isChecked}
            defaultValue={selectedRowData.UserGroup}
            onChange={handleDropdownChange}
          />
          <Dropdown
            labelTitle="User Branch"
            name="User Branch"
            options={DropDown.BranchMaster || []}
            defaultValue={selectedRowData.SourceBranch}
            onChange={handleDropdownChange}
          />
        </div>
      );
    }
    return null; // If isChecked is false, return null
  };

  const checkHandler = () => {
    setIsChecked(!isChecked);
  };

  const handleCheckboxChange = (e) => {
    checkHandler(); // Call checkHandler
    // Call the inline arrow function with the event
    updateFormValue(e.target.name, e.target.checked);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* General Information */}
      <div className="grid grid-cols-1">
        <div className="grid gap-4 lg:grid-cols-1 md:grid-cols-1">
          <div className="gap-6">
            {/* <div className="font-semibold text-lg">Report Master</div>
          <div className="divider"></div> */}
            <InputText
              name="UserCode"
              type="text"
              labelTitle="Group Code"
              defaultValue={selectedRowData.UserCode}
              placeholder={"Group Code"}
              updateFormValue={updateFormValue}
              sup
            />
            {errors.UserCode && (
              <div className="text-red-500">{errors.UserCode}</div>
            )}
            <InputText
              name="UserName"
              type="text"
              labelTitle="Group Name"
              defaultValue={selectedRowData.UserName}
              updateFormValue={updateFormValue}
              placeholder={"Group Name"}
              sup
            />
            {errors.UserName && (
              <div className="text-red-500">{errors.UserName}</div>
            )}
            <div className="pt-5 ">
              <input
                name="IsActive"
                type="checkbox"
                className="mx-4"
                defaultChecked={selectedRowData.IsActive}
                onChange={handleCheckboxChange}
                // onClick={openDDL}
              />
              Is Active
            </div>
          </div>
        </div>

        <div></div>
        <div className="flex flex-col md:flex-row justify-between mt-4 gap-4">
          <button onClick={handleBack} className="btn w-full md:w-24">
            Back
          </button>
          <button
            type="submit"
            className="btn bg-gray-800 text-white hover:bg-neutral-400 border-none hover:text-black px-6 btn-sm normal-case md:ml-4"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserMasterGroupForm;
