import React from "react";

const Dropdown = ({
  sup,
  labelTitle,
  labelValue = "Value",
  labelText = "Text",
  options = [],
  value,
  onChange,
  name,
  multiple,
  visibility=false,
}) => {
  return (
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text text-base-content font-medium">
         <div className="text-sm text-gray-500">
         {labelTitle}  {sup && <sup className="text-red-500">*</sup>}
         </div>
        </span>
      </label>
      <select
        name={name}
        className={  `border-slate-300 border-double outline-offset-4 outline-slate-300 rounded-md h-${
          multiple ? 20 : 10
        } p-0 pl-3  my-2 border-2 w-full 
       
        `}
        // ${visibility? invisible :''}
        defaultValue={  multiple ? value : null}
        value={value}
        multiple={multiple}
        onChange={onChange}
      >
         {multiple ? null : (
        <option value="">
          <p className="text-sm text-gray-500">Please Select</p>
        </option>
      )}

        {/* {options.map((option) => (
          <option key={option[labelValue]} value={option[labelValue]}>
            {option[labelText]}
          </option>
        ))} */}
        {options.map((option) => (
          <option
            key={option.Value || option[labelValue]}
            value={option.Value || option[labelValue]}

          >
            {option.Text || option[labelText]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;











// import React from "react";

// const Dropdown = ({
//   sup,
//   labelTitle,
//   labelValue = "Value",
//   labelText = "Text",
//   options = [],
//   value,
//   onChange, // Ensure this prop is correctly passed and defined as a function
//   name,
//   multiple,
//   visibility = true,
// }) => {
//   const handleChange = (e) => {
//     if (onChange) {
//       onChange(e.target.name,e.target.value);
//     }
//   };

//   const renderOptions = () => {
//     return options.map((option) => (
//       <option
//         key={option[labelValue] || option.Value}
//         value={option[labelValue] || option.Value}
//       >
//         {option[labelText] || option.Text}
//       </option>
//     ));
//   };

//   return visibility ? (
//     <div className="form-control w-full">
//       <label className="label">
//         <span className="label-text text-base-content font-medium">
//           {labelTitle} {sup && <sup className="text-red-500">*</sup>}
//         </span>
//       </label>
//       <select
//         name={name}
//         className={`border-slate-300 border-double outline-offset-4 outline-slate-300 rounded-md h-${
//           multiple ? 20 : 10
//         } p-0 pl-3 border-2 w-full`}
//         defaultValue={multiple ? value : ""}
//         value={value}
//         multiple={multiple}
//         onChange={handleChange}
//       >
//         {!multiple && <option value="">Please Select</option>}
//         {renderOptions()}
//       </select>
//     </div>
//   ) : null;
// };

// export default Dropdown;
