import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Avatar from "../components/Avatar";
import { BsInfoCircle } from "react-icons/bs";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import axios from "axios";
import Pagination from "../components/Pagination";
import "react-responsive-modal/styles.css"; // Ensure this is imported
import "../Modal/Modal.css";
import { FaEye } from "react-icons/fa";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "../Modal/Modal.css";

const PageSize = 10;
const OrgCardpage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [representative, setrepresentative] = useState();
  const [SelectedRowData, setSelectedRowData] = useState();
  const [Activity, setActivity] = useState(null);
  const location = useLocation();
  const navigatepage = useNavigate();
  const [activeSection, setActiveSection] = useState(null);

  const user = location.state?.user;
  const orgId = location.state?.orgId; // Get OrgID from state
  const [newdata, setnewdata] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/organizationcarddetails/${orgId}` // Use orgId here
        );
        const data = await response.json();
        setActivity(data.creationInfo);
        setSelectedRowData(data);
        console.log("setSelectedRowData", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (orgId) {
      fetchData(); // Fetch data if orgId is available
    }
  }, [orgId]);

  const serviceProviderData = SelectedRowData?.[0]?.[0];
  // const representativesData = SelectedRowData?.[1] || [];

  const representativesData = SelectedRowData?.[1] || [];
  console.log("Representatives Data:", representativesData);



const firstPageIndex = (currentPage - 1) * PageSize;
const lastPageIndex = firstPageIndex + PageSize;
const currentTableData = representativesData.slice(firstPageIndex, lastPageIndex);
console.log("Current Table Data:", currentTableData);


  return (
    <div className="container mx-auto px-2">
      <div className="bg-white rounded-lg shadow-2xl p-4 my-8">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <div className="flex flex-col md:flex-row gap-5">
            <div className="md:text-left">
              <h2 className="md:text-1xl font-bold text-gray-800">
                {serviceProviderData?.SourceCountry || "Not Available"}
              </h2>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Name Of Service Provider:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.NameOfServiceProvider}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Client Type:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.ClientType}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Is Outsourced Via Tender:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.IsOutsourcedViaTender}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Possibility To Extend:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.IsPossibilityToExtend}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Scope Offer Skylane Solutions:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.IsScopeOfferSkylaneSolutions}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Is Visa Solutions Outsourced:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.IsVisaSolutionsOutsourced}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Contract Validity Period

            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.CurrentContractValidityPeriod}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Jurisdiction:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.Jurisdiction}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              National Day:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.NationalDay}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Extension Period:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.ExtensionPeriod}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
            Is Outsourced Via Tender:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.IsOutsourcedViaTender}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
            Tendering Through
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.TenderingThrough}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
            Approx Applicant Volumes
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.ApproxApplicantVolumes}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              City:
            </p>
            <p className="text-gray-600 text-sm">{serviceProviderData?.City}</p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Address:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.Address}
            </p>
          </div>
          <div>
            <p className="py-2 whitespace-nowrap text-sm font-semibold">
              Client Country:
            </p>
            <p className="text-gray-600 text-sm">
              {serviceProviderData?.ClientCountry || "Not Available"}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-2xl p-4 my-4">
        <h1 className=" md:text-1xl font-bold text-gray-800">
          Representatives
        </h1>
        <div className="overflow-x-auto">
          {/* <table className="table w-full mt-4 md:mt-6 lg:mt-8">
            <thead className="bg-gray-100 text-left">
              <tr className="text-gray-500 justify-between">
                <th className="text-xs md:text-sm py-2 px-4">Name</th>
                <th className="text-xs md:text-sm py-2 px-4">Phone</th>
                <th className="text-xs md:text-sm py-2 px-4">Email</th>
                <th className="text-xs md:text-sm py-2 px-4">Action</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentTableData.length === 0 ? (
                <tr>
                  <td colSpan={4} className="text-center py-4 text-gray-500">
                    Data not available
                  </td>
                </tr>
              ) : (
                currentTableData.map((entity, k) => (
                  <tr className="text-left" key={k}>
                    <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm flex gap-4">
                      {entity.Name}{" "}
                      <button
                        className="mt-1"
                        onClick={() =>
                          navigatepage(`/Linkpage/${entity.EntityID}`)
                        }
                      >
                        <BsInfoCircle className="text-pink-700" />
                      </button>
                    </td>
                    <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                      {entity.Phone}
                    </td>
                    <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                      {entity.Email}
                    </td>
                    <td className="p-2 whitespace-nowrap px-8 ">
                      <button
                        className="text-blue-500"
                        onClick={() => {
                          setSelectedEntity(entity);
                          setIsModalOpen(true);
                        }}
                      >
                        <FaEye />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table> */}
          <table className="table w-full mt-4 md:mt-6 lg:mt-8">
            <thead className="bg-gray-100 text-left">
              <tr className="text-gray-500 justify-between">
                <th className="text-xs md:text-sm py-2 px-4">Name</th>
                <th className="text-xs md:text-sm py-2 px-4">Phone</th>
                <th className="text-xs md:text-sm py-2 px-4">Email</th>
                <th className="text-xs md:text-sm py-2 px-4">Action</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentTableData.length === 0 ? (
                <tr>
                  <td colSpan={4} className="text-center py-4 text-gray-500">
                    Data not available
                  </td>
                </tr>
              ) : (
                currentTableData.map((entity, k) => (
                  <tr className="text-left" key={k}>
                    <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm flex gap-4">
                      {entity.Name}{" "}
                      <button
                        className="mt-1"
                        onClick={() =>
                          navigatepage(`/Linkpage/${entity.EntityID}`)
                        }
                      >
                        <BsInfoCircle className="text-pink-700" />
                      </button>
                    </td>
                    <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                      {entity.Phone}
                    </td>
                    <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                      {entity.Email}
                    </td>
                    <td className="p-2 whitespace-nowrap px-8">
                      <button
                        className="text-blue-500"
                        onClick={() => {
                          setSelectedEntity(entity);
                          setIsModalOpen(true);
                        }}
                      >
                        <FaEye />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(true)}
            center
            classNames={{ modal: "custom-modal" }}
            showCloseIcon={false}
          >
            <div className="p-2 bg-white rounded-lg ">
              <div className="">
                <h1 className=" md:text-1xl font-bold text-gray-800">
                  {selectedEntity?.Name || "--"}
                </h1>

                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Type:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.Type || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Phone:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.Phone || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Email:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.Email || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Date Of Birth:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {" "}
                    {selectedEntity?.DateOfBirth || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">LandLine Number:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {" "}
                    {selectedEntity?.LandLineNumber || "--"}
                  </p>
                </p>

                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">City:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {" "}
                    {selectedEntity?.City || "--"}
                  </p>
                </p>

                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">
                    Relationship Quotient:
                  </p>{" "}
                  <p className="text-xs md:text-sm">
                    {" "}
                    {selectedEntity?.RelationshipQuotient || "--"}
                  </p>
                </p>

                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Secondary EmailID</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.SecondaryEmailID || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Secondary MobileNo</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.SecondaryMobileNo || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Organization:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.Organization || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Is Transferred:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.IsTransferred || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">GeographiesServed:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.GeographiesServed || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Designation:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.Designation || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">
                    Date Of Anniversary:
                  </p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.DateOfAnniversary || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">Addiitonal EmailID:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.AddiitonalEmailID || "--"}
                  </p>
                </p>

                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">
                    Additional Contact No:
                  </p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.AdditionalContactNo || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">
                    {" "}
                    Additional Mobile No:
                  </p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.AdditionalMobileNo || "--"}
                  </p>
                </p>
                <p className="text-gray-700 flex justify-between">
                  <p className="text-xs md:text-sm pb-1">BusinessCard:</p>{" "}
                  <p className="text-xs md:text-sm">
                    {selectedEntity?.BusinessCard || "--"}
                  </p>
                </p>
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className="w-full my-2 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-200"
              >
                Close
              </button>
            </div>
          </Modal>

          <Pagination
            className="pagination-bar mt-4"
            currentPage={currentPage}
            totalCount={data.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default OrgCardpage;
