import React, { useEffect, useState } from "react";
import axios from "axios";
import InputText from "../Input/InputText";
import Dropdown from "../Input/DropDown";
import TextAreaInput from "../Card/TextAreaInput";
import { useUser } from "../Context/UserContext";
import { useAuth } from "../Context/AuthContext";
import TitleCard from "../Card/TitleCard";
import { message } from "antd";
import {
  VALIDATION_ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  Messages,
} from "../message.js";
// const CreateMeeting = ({ user, meetingData }) => {
//   const { userid } = useUser(); // Get user data from context
//   const { userdata } = useAuth();
//   const [dropdownData, setDropdownData] = useState([]);
//   const [errors, setErrors] = useState({});

//   console.log("meetingData :",meetingData);
//   const [inputData, setInputData] = useState(meetingData|| {
//     Type: "Meeting",
//     Title: "",
//     Date: "",
//     Time: "",
//     Venue: "",
//     Note: "",
//     Upload: "",
//     ReminderDate: "",
//     EmailID: "",
//     ActionPlan: "",
//     ClientMission: "",
//     SPOC: "",
//     PersonMet: "",
//     CreatedBy: userdata?.UserID,
//     ModifiedBy: userdata?.UserID,
//     EntityID: user.EntityID,
//     UpdateOnClientDir:0,
//   } );
//   console.log("CreatedBy", userdata.UserID);
//   const [message, setMessage] = useState(""); // State for success/error messages
//   const [showMessage, setShowMessage] = useState(false); // State for showing/hiding message

//   const [selectedFile, setSelectedFile] = useState(null);
//   const [previewUrl, setPreviewUrl] = useState("");

//   const handleFileChange = (event) => {
//     if (event.target.files.length > 0) {
//       const file = event.target.files[0];
//       setSelectedFile(file);
//       const fileUrl = URL.createObjectURL(file);
//       setPreviewUrl(fileUrl);
//     }
//   };

//   // Handle input changes
//   const updateFormValue = (name, value) => {
//     setInputData({
//       ...inputData,
//       [name]: value,
//     });
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       console.log("inputData submit create meeting :", inputData);
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_BASE_URL}/AtlysinsertmeetingSchedules`,
//         inputData
//       );
//       setMessage("Meeting schedule created successfully!");
//       setShowMessage(true);
//       setInputData({
//         Type: "Meeting",
//         Title: "",
//         Date: "",
//         Time: "",
//         Venue: "",
//         Note: "",
//         Upload: "",
//         ReminderDate: "",
//         EmailID: "",
//         ActionPlan: "",
//         SPOC: "",
//         ClientMission: "",
//         PersonMet: "",
//         UpdateOnClientDir: "",
//         EntityID: user.EntityID,
//       });
//     } catch (error) {
//       console.error("There was an error!", error);
//       setMessage("There was an error submitting the form. Please try again.");
//       setShowMessage(true);
//     }
//   };

//   const Type = [
//     { Text: "Meeting", Value: "Meeting" },
//     // { Text: "External", Value: "E" },
//   ];

//   const handleDropdownChange = (e) => {
//     const { name, value } = e.target;
//     setInputData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   // Function to hide the message
//   const handleCloseMessage = () => {
//     setShowMessage(false);
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);
//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_BASE_URL}/GetBindDropdownForCountry`
//       );
//       setDropdownData(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   return (
//     <div>
//         <TitleCard title={"Create Meeting"}>
//       <form className="" onSubmit={handleSubmit}>
//         <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1">
//           <InputText
//             type={"date"}
//             name="Date"
//             value={inputData.Date}
//             updateFormValue={updateFormValue}
//             labelTitle="Date"

//           />
//           {/* <Dropdown
//             labelTitle={"Client Mission"}
//             name="ClientMission"
//             value={inputData.ClientMission}
//             updateFormValue={updateFormValue}
//             onChange={handleDropdownChange}
//             options={dropdownData || []}
//             labelText={"CountryName"}
//             labelValue={"CountryID"}

//           /> */}
//           {/* <InputText
//             name="PersonMet"
//             value={inputData.PersonMet}
//             updateFormValue={updateFormValue}
//             labelTitle="Person Met"

//           /> */}

//           <TextAreaInput
//             name="Note"
//             value={inputData.Note}
//             updateFormValue={updateFormValue}
//             labelTitle="Key Discussion Points"

//           />
//           <TextAreaInput
//             name={"ActionPlan"}
//             labelTitle={"	Action Plan "}
//             value={inputData.ActionPlan}
//             updateFormValue={updateFormValue}

//           />

//           <InputText
//             type={"date"}
//             name="ReminderDate"
//             value={inputData.ReminderDate}
//             updateFormValue={updateFormValue}
//             labelTitle="ReminderDate"

//           />
//           <InputText
//             name="SPOC"
//             labelTitle={"SPOC"}
//             value={inputData.SPOC}
//             updateFormValue={updateFormValue}

//           />

//           {/* <InputText
//             name="Title"
//             value={inputData.Title}
//             updateFormValue={updateFormValue}
//             labelTitle="Title"

//           /> */}

//           <InputText
//             name="Time"
//             type={"Time"}
//             value={inputData.Time}
//             updateFormValue={updateFormValue}
//             labelTitle="Time"

//           />
//             <div>
//             <input
//               name="UpdateOnClientDir"
//               type="checkbox"
//               className="mx-4"
//               checked={inputData.UpdateOnClientDir}
//               onChange={(e) => updateFormValue(e.target.name, e.target.checked)}
//             />
//             Details Updated on Client Directory
//           </div>

//           {/* <InputText
//             name="Venue"
//             value={inputData.Venue}
//             updateFormValue={updateFormValue}
//             labelTitle="Venue"

//           /> */}

//           {/* <InputText
//             type={"Email"}
//             name="EmailID"
//             value={inputData.EmailID}
//             updateFormValue={updateFormValue}
//             labelTitle="EmailID"

//           /> */}

//           <main className="flex items-center justify-center font-sans">
//             <label
//               htmlFor="dropzone-file"
//               className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-gray-600 bg-white p-6 text-center"
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-10 w-10 text-blue-500"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//                 strokeWidth="2"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
//                 />
//               </svg>

//               <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">
//                 Supportive
//               </h2>

//               <p className="mt-2 text-gray-500 tracking-wide">
//                 Upload or drag & drop your file SVG, PNG, JPG or GIF.
//               </p>

//               <input
//                 id="dropzone-file"
//                 type="file"
//                 className="hidden"
//                 onChange={handleFileChange}
//               />

//               {/* Preview */}
//               {previewUrl && (
//                 <div className="mt-4">
//                   <img
//                     src={previewUrl}
//                     alt="Preview"
//                     className="max-w-full max-h-64 rounded-md border border-gray-300"
//                   />
//                 </div>
//               )}
//             </label>
//           </main>
//           <div className=" relative">
//             <button
//               type="submit"
//               className=" bottom right-0 text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800 shadow-lg shadow-gray-500/50 dark:shadow-lg dark:shadow-gray-800/80 font-medium rounded-lg text-sm px-5 py-3 my-3 text-center me-2 mb-2"
//             >
//               Submit
//             </button>
//           </div>
//         </div>
//       </form>
//       </TitleCard>
//       {showMessage && message && (
//         <div
//           className={`mt-4 p-4 ${
//             message.includes("error")
//               ? "bg-red-200 text-red-800"
//               : "bg-green-200 text-green-800"
//           } border rounded relative`}
//         >
//           <button
//             onClick={handleCloseMessage}
//             className="absolute top-2 right-2 text-lg font-bold"
//           >
//             &times; {/* This is a "×" character */}
//           </button>
//           {message}
//         </div>
//       )}
//     </div>

//   );
// };

// export default CreateMeeting;

const CreateMeeting = ({ user, meetingData }) => {
  const { userid } = useUser();
  const { userdata } = useAuth();
  const [dropdownData, setDropdownData] = useState([]);
  const [errors, setErrors] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  const [inputData, setInputData] = useState(
    meetingData || {
      SysID: "",
      Type: "Meeting",
      Title: "",
      Date: "",
      Time: "",
      Venue: "",
      Note: "",
      Upload: "",
      ReminderDate: "",
      EmailID: "",
      ActionPlan: "",
      ClientMission: "",
      SPOC: "",
      PersonMet: "",
      // CreatedBy: userdata?.UserID,
      // ModifiedBy: userdata?.UserID,
      // EntityID: user.EntityID,
      CreatedBy: user.EntityID,
      ModifiedBy: user.EntityID,
      EntityID: user.EntityID,
      UpdateOnClientDir: 0,
    }
  );

  //const [message, setMessage] = useState("");

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }
  };

  const updateFormValue = (name, value) => {
    setInputData({
      ...inputData,
      [name]: value,
    });
  };
  const handleCloseMessage = () => {
    setShowMessage(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response;
      if (inputData.SysID) {
        console.log("Updating meeting with data:", inputData);
        response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/updateMeetingSchedule`,
          inputData
        );
        message.success(Messages.Messages_Meeting_Successfully_Edited);
      } else {
        console.log("Creating new meeting with data:", inputData);
        response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/AtlysinsertmeetingSchedules`,
          inputData
        );
        message.success(Messages.Messages_Meeting_Successfully);
      }

      setShowMessage(true);
      setInputData({
        SysID: "",
        Type: "Meeting",
        Title: "",
        Date: "",
        Time: "",
        Venue: "",
        Note: "",
        Upload: "",
        ReminderDate: "",
        EmailID: "",
        ActionPlan: "",
        SPOC: "",
        ClientMission: "",
        PersonMet: "",
        UpdateOnClientDir: 0,
        CreatedBy: user.EntityID,
        ModifiedBy: user.EntityID,
        EntityID: user.EntityID,
        EntityID: user.EntityID,
      });
    } catch (error) {
      console.error(
        "There was an error!",
        error.response ? error.response.data : error.message
      );
      message.error(Messages.Messages_Meeting_Error);
      // setMessage("There was an error submitting the form. Please try again.");
      setShowMessage(true);
    }
  };
  console.log("Sending data to update:", inputData);
  // ... Rest of your code remains unchanged

  return (
    <div>
      <TitleCard title={"Create Meeting"}>
        <form className="" onSubmit={handleSubmit}>
          <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1">
            <InputText
              type={"date"}
              name="Date"
              value={inputData.Date}
              updateFormValue={updateFormValue}
              labelTitle="Date"
            />
            {/* <Dropdown
                labelTitle={"Client Mission"}
                name="ClientMission"
                value={inputData.ClientMission}
                updateFormValue={updateFormValue}
                onChange={handleDropdownChange}
                options={dropdownData || []}
                labelText={"CountryName"}
                labelValue={"CountryID"}
           
              /> */}
            {/* <InputText
                name="PersonMet"
                value={inputData.PersonMet}
                updateFormValue={updateFormValue}
                labelTitle="Person Met"
               
              /> */}

            <TextAreaInput
              name="Note"
              value={inputData.Note}
              updateFormValue={updateFormValue}
              labelTitle="Key Discussion Points"
            />
            <TextAreaInput
              name={"ActionPlan"}
              labelTitle={"	Action Plan "}
              value={inputData.ActionPlan}
              updateFormValue={updateFormValue}
            />

            <InputText
              type={"date"}
              name="ReminderDate"
              value={inputData.ReminderDate}
              updateFormValue={updateFormValue}
              labelTitle="ReminderDate"
            />
            <InputText
              name="SPOC"
              labelTitle={"SPOC"}
              value={inputData.SPOC}
              updateFormValue={updateFormValue}
            />

            {/* <InputText
                name="Title"
                value={inputData.Title}
                updateFormValue={updateFormValue}
                labelTitle="Title"
               
              /> */}

            <InputText
              name="Time"
              type={"Time"}
              value={inputData.Time}
              updateFormValue={updateFormValue}
              labelTitle="Time"
            />
            <div>
              <input
                name="UpdateOnClientDir"
                type="checkbox"
                className="mx-4"
                checked={inputData.UpdateOnClientDir}
                onChange={(e) =>
                  updateFormValue(e.target.name, e.target.checked)
                }
              />
              Details Updated on Client Directory
            </div>

            {/* <InputText
                name="Venue"
                value={inputData.Venue}
                updateFormValue={updateFormValue}
                labelTitle="Venue"
                
              /> */}

            {/* <InputText
                type={"Email"}
                name="EmailID"
                value={inputData.EmailID}
                updateFormValue={updateFormValue}
                labelTitle="EmailID"
                
              /> */}

            <main className="flex items-center justify-center font-sans">
              <label
                htmlFor="dropzone-file"
                className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-gray-600 bg-white p-6 text-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 text-blue-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>

                <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">
                  Supportive
                </h2>

                <p className="mt-2 text-gray-500 tracking-wide">
                  Upload or drag & drop your file SVG, PNG, JPG or GIF.
                </p>

                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                />

                {/* Preview */}
                {previewUrl && (
                  <div className="mt-4">
                    <img
                      src={previewUrl}
                      alt="Preview"
                      className="max-w-full max-h-64 rounded-md border border-gray-300"
                    />
                  </div>
                )}
              </label>
            </main>
            <div className=" relative">
              <button
                type="submit"
                className=" bottom right-0 text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800 shadow-lg shadow-gray-500/50 dark:shadow-lg dark:shadow-gray-800/80 font-medium rounded-lg text-sm px-5 py-3 my-3 text-center me-2 mb-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </TitleCard>
    </div>
  );
};

export default CreateMeeting;
