import Subtitle from "./Subtitle";

function TitleCard({ title, children, topMargin, TopSideButtons }) {
  return (
    <div
      className={`w-full   p-6 bg-white shadow-2xl ${
        topMargin || "mt-6"
      } rounded-lg`}
    >
      {/* Title for Card */}
      <div className="flex flex-col md:flex-row items-start justify-between">
        <Subtitle
          styleClass={`text-lg font-semibold ${
            TopSideButtons ? "mb-2 md:mb-0" : ""
          }`}
        >
          {title}
        </Subtitle>

        {/* Top side button, show only if present */}
        {TopSideButtons && (
          <div className="mt-2 md:mt-0 md:ml-4 flex-shrink-0">
            {TopSideButtons}
          </div>
        )}
      </div>

     

      {/* Card Body */}
      <div className="h-full w-full  bg-base-100">{children}</div>

    
    </div>
  );
}

export default TitleCard;
