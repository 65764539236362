// TextAreaInput.js
import React from "react";

const TextAreaInput = ({
  name,
  type,
  labelTitle,
  defaultValue,
  updateFormValue,
  placeholder,
  width,
  rows = 4,
  sup,
  error,
  value,
}) => {
  const handleChange = (e) => {
    updateFormValue(e.target.name, e.target.value);
  };

  const textAreaStyle = {
    resize: "both", // Allow both horizontal and vertical resizing
    overflow: "auto", // Ensure scrollbars appear when needed
    width: width ? `${width}em` : `100%`,
  };

  return (
    <div className={`form-control w-full`}>
      <label className="label">
        <div className="text-sm text-gray-500 ">
          {labelTitle} {sup && <sup className="text-red-500">*</sup>}{" "}
        </div>
      </label>
      <textarea
        type={type || "text"}
        name={name}
        style={textAreaStyle}
        className=" rounded-md border-double outline-offset-4 outline-slate-300 w-full border-slate-300 border-2 pl-3 pt-2"
        rows={rows}
        // cols={40}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
      />{" "}
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export default TextAreaInput;
