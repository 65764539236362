import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  EnvelopeIcon,
  LockClosedIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../Context/AuthContext";
import logo from "../images/atlys.png";

const secretKey = process.env.REACT_APP_SECRET_KEY;

const Loginpage = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [inputdata, setInputdata] = useState({ username: '', password: '' });
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth();
  
  // Create a state for user data
  const [user, setUser] = useState(null); 

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateUserName = (userName) => {
    return userName.trim() !== "";
  };

  const validatePassword = (password) => {
    return password.trim() !== "";
  };

  const validateForm = () => {
    let isValid = true;
    setUserNameError("");
    setPasswordError("");
    setError("");

    if (!validateUserName(inputdata.username)) {
      setUserNameError("Username cannot be empty");
      isValid = false;
    }

    if (!validatePassword(inputdata.password)) {
      setPasswordError("Please enter a password");
      isValid = false;
    }

    return isValid;
  };

  const handleLogin = async (event) => {
    event.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    // Generate a new IV
    const customIV = CryptoJS.lib.WordArray.random(16); // 16 bytes IV for AES encryption
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(inputdata),
      secretKey,
      { iv: customIV }
    ).toString();
  
    // Prepare the payload to send to the server
    const payload = {
      iv: customIV.toString(CryptoJS.enc.Base64), // Convert IV to Base64 for sending
      encryptedData: encryptedData
    };
  
    console.log("Login Input Data:", inputdata);
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin`, payload);
  
      if (response.status === 200) {
        console.log(' userData = response.data.data[0] :',response.data.data)  
        const userData = response.data.data; // Adjust based on your API response structure
        setUser(userData); // Set the user data
        setInputdata(userData);
        login(userData); // Assuming login updates the context
        navigate("/Dashboard");
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Incorrect username or password");
      } else {
        setError("Login failed. Please try again.");
      }
    }
  };
  
  return (
    <div>
      <div className="flex items-center justify-center mt-36">
        <div className="flex w-auto flex-col md:w-1/2">
          <div className="md:shadow-2xl py-10 px-2 lg:w-[28rem] mx-auto my-auto flex flex-col justify-center md:justify-start md:px-6 md:pt-0">
            <div className="flex justify-center">
              <img src={logo} alt="" className="w-full max-w-xs h-auto object-cover" />
            </div>

            <form className="flex flex-col pt-3 md:pt-8" onSubmit={handleLogin}>
              <div className="relative flex flex-col mb-6">
                <EnvelopeIcon className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                <input
                  type="text"
                  id="userName"
                  value={inputdata.username}
                  onChange={(e) => setInputdata({ ...inputdata, username: e.target.value })}
                  className={`w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-base text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:text-gray-900 ${userNameError ? "border-red-500" : ""}`}
                  placeholder="Username"
                />
                {userNameError && (
                  <p className="text-red-500 text-sm mt-1">{userNameError}</p>
                )}
              </div>
              <div className="relative flex flex-col mb-6">
                <LockClosedIcon className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  value={inputdata.password}
                  onChange={(e) => setInputdata({ ...inputdata, password: e.target.value })}
                  className={`w-full pl-10 pr-12 py-2 border border-gray-300 rounded-lg text-base text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:text-gray-900 ${passwordError ? "border-red-500" : ""}`}
                  placeholder="Password"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-3"
                >
                  {passwordVisible ? (
                    <EyeSlashIcon className="w-5 h-5 text-gray-400" />
                  ) : (
                    <EyeIcon className="w-5 h-5 text-gray-400" />
                  )}
                </button>
                {passwordError && (
                  <p className="text-red-500 text-sm mt-1">{passwordError}</p>
                )}
              </div>
              {error && (
                <p className="text-red-500 text-center mb-4">{error}</p>
              )}

              <button
                type="submit"
                className="w-full rounded-lg bg-gray-900 px-4 py-2 text-center text-base font-semibold text-white shadow-md ring-gray-500 ring-offset-2 transition focus:ring-2"
              >
                Log in
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginpage;






