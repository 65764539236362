import React from "react";

const InputText = ({
  multiple,
  sup,
  max,
  type,
  name,
  labelTitle,
  value,
  updateFormValue,
  placeholder,
  readonly,
  defaultValue,
  pattern,
  maxlength,
  error 
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!pattern || new RegExp(pattern).test(value)) {
      updateFormValue(name, value);
    }
  };

  return (
    <div className={`form-control w-full`}>
      <label className="label">
        <span className={"label-text text-base-content font-medium "}>
          <div className="text-sm text-gray-500 ">
            {labelTitle} {sup && <sup className="text-red-500">*</sup>}{" "}
          </div>
        </span>
      </label>
      <input
        max={max}
        multiple={multiple}
        pattern={pattern}
        maxLength={maxlength} // Set maxlength attribute for input
        type={type || "text"}
        name={name}
        className={` border-slate-300
          ${readonly ? "cursor-not-allowed" : "cursor-default"}
          rounded-md h-10 p-0 pl-3 border-2  my-2 w-full`}
        readOnly={readonly}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
      />
       {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export default InputText;
