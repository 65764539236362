import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Dashboard from "./Dashboard";
const Layout = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    console.log("Location At DashBoard", location);
  }, [location]);

  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
  };

  return (
    <div className="flex h-screen  ">
      <div>
        {" "}
        {isSidebarVisible && <Sidebar toggleSidebar={toggleSidebar} />}
      </div>

      <div className="flex flex-col w-full overflow-auto  ">
        <Header
          toggleSidebar={toggleSidebar}
          isSidebarVisible={isSidebarVisible}
        />
        <Dashboard />
      </div>
    </div>




//     <div className="relative h-screen flex">
//   {isSidebarVisible && (
//     <div className={`absolute inset-y-0 left-0 z-50 transition-all duration-300 ${isSidebarVisible ? 'w-64 md:w-64' : 'w-0'} md:w-64 w-full`}>
//       <Sidebar toggleSidebar={toggleSidebar} />
//     </div>
//   )}

//   <div className={`flex flex-col w-full transition-all duration-300 ${isSidebarVisible ? 'ml-64' : 'ml-0'}`}>
//     <Header toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
//     <div className="flex-1 overflow-auto">
//       <Dashboard />
//     </div>
//   </div>
// </div>

  );
};

export default Layout;
