
import axios from "axios";
import React, { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import ConfirmationModal from "../components/ConfirmationDeleteModal"; // Import the modal
import CryptoJS from "crypto-js";
import { AiOutlineEdit } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { decode } from "../utils";
import { MdOutlineDeleteForever } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { message } from "antd";
import { VALIDATION_ERROR_MESSAGES, SUCCESS_MESSAGES , Messages} from "../message.js";
const PageSize = 10;
const secretKey = process.env.REACT_APP_SECRET_KEY;

const EntityMastermain = ({ changeForms }) => {
  const [EntityMaster, setEntityMaster] = useState([]);
  const [SearchQuery, setSearchquery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [rightsarr, setRightsarr] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const decrypt = decode(id);
      const decryptedData = JSON.parse(decrypt.Rights);
      setRightsarr(decryptedData);
    }
  }, [id]);

  // const handleDelete = async () => {
  //   if (userToDelete === null) return;

  //   try {
  //     await axios.delete(
  //       `${process.env.REACT_APP_API_BASE_URL}/DeleteAtlysEntityMaster`,
  //       {
  //         data: { EntityID: userToDelete },
  //       }
  //     );

  //     setEntityMaster((prevEntities) =>
  //       prevEntities.filter((entity) => entity.EntityID !== userToDelete)
  //     );

  //     setUserToDelete(null);
  //     setShowModal(false);

  //     message.delete(" deleted successfully.");
  //   } catch (error) {
  //     console.error("Error deleting entity:", error);
  //   }
  // };




  const handleDelete = async () => {
    if (userToDelete === null) return;
  
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/DeleteAtlysEntityMaster`,
        {
          data: { EntityID: userToDelete },
        }
      );
  
      setEntityMaster((prevEntities) =>
        prevEntities.filter((entity) => entity.EntityID !== userToDelete)
      );
  
      setUserToDelete(null);
      setShowModal(false);
  
      // Display a success message
      message.success(Messages.Messages_Deleted);
      //message.success(" deleted successfully."); // Adjusted to `message.success`
    } catch (error) {
      console.error("Error deleting entity:", error);
      message.error(Messages.Messages_Deleted_Error); // Optionally handle the error message
    }
  };
  
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/BindGridAtlysEnityMaster`)
      .then((res) => {
        setEntityMaster(res.data);
      });
  }, []);

  const handleEdit = async (EntityID) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/GetAtlysPopulateEnityMaster/${EntityID}`
      );
      // const bytes = CryptoJS.AES.decrypt(response.data, secretKey);
      // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      // setSelectedRowData(JSON.parse(decryptedData));
      // changeForms(JSON.parse(decryptedData)[0]);
      setSelectedRowData(response.data);
      changeForms(response.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const filteredData = EntityMaster.filter(
    (item) =>
      (item.EntityID && item.EntityID.toString().includes(SearchQuery)) ||
      (item.Type &&
        item.Type.toLowerCase().includes(SearchQuery.toLowerCase())) ||
      (item.city &&
        item.city.toLowerCase().includes(SearchQuery.toLowerCase())) ||
      (item.Phone &&
        item.Phone.toLowerCase().includes(SearchQuery.toLowerCase())) ||
      (item.Email &&
        item.Email.toLowerCase().includes(SearchQuery.toLowerCase())) ||
      (item.Name && item.Name.toLowerCase().includes(SearchQuery.toLowerCase()))
  );

  // Pagination
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = filteredData.slice(firstPageIndex, lastPageIndex);

  const ChangeEntityMaster = () => {
    changeForms();
  };

  const openModal = (EntityID) => {
    setUserToDelete(EntityID);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setUserToDelete(null);
  };

  return (
    <div className="py-4  ">
      <div className="flex flex-col md:flex-row justify-between mb-4 mt-4 gap-3">
        <input
          placeholder="Search......"
          type="text"
          //  className="input input-bordered w-full md:w-1/2 lg:w-1/3 mb-2 md:mb-0"
          className={` border-slate-300
          "cursor-not-allowed" : "cursor-default"
         rounded-md h-10 p-0 pl-3 border-2   sm:mt-2 w-full md:w-1/2 lg:w-1/3 `}
          value={SearchQuery}
          onChange={(e) => setSearchquery(e.target.value)}
        />
        {console.log('rightsarr entity :',rightsarr)}
        {rightsarr[1] !== 0 && (
          <button
            className="btn bg-gray-800 text-white hover:bg-neutral-400 border-none hover:text-black px-6 btn-sm normal-case md:ml-4"
            onClick={() => {
              changeForms();
            }}
          >
            <IoIosAdd className="text-white h-6 w-6   hover:text-black " />
            New
          </button>
        )}
      </div>

      <div className="overflow-x-auto">
        <table className="table w-full mt-4 md:mt-6 lg:mt-8">
          <thead className="bg-gray-100 text-left">
            <tr className="text-gray-500">
              <th className="text-xs md:text-sm py-2 px-4">Name</th>
              <th className="text-xs md:text-sm py-2 px-4">Type</th>
              <th className="text-xs md:text-sm py-2 px-4">City</th>
              <th className="text-xs md:text-sm py-2 px-4">Phone</th>
              <th className="text-xs md:text-sm py-2 px-4">Email</th>
              <th className="text-xs md:text-sm py-2 px-4">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentTableData.map((entity, k) => (
              <tr className="text-left" key={k}>
                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                  {entity.Name}
                </td>
                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                  {entity.Type}
                </td>
                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                  {entity.city}
                </td>
                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                  {entity.Phone}
                </td>
                <td className="px-4 py-2 md:py-4 whitespace-nowrap text-sm">
                  {entity.Email}
                </td>
                <div className="flex py-4   items-center  gap-8">
                  {rightsarr[2] !== 0 && (
                    <button
                      className="text-green-500 hover:text-green-600 flex items-center"
                      onClick={() => handleEdit(entity.EntityID, changeForms)}
                    >
                      <AiOutlineEdit className="h-6 w-6" />
                    </button>
                  )}

                  {rightsarr[0] !== 0 && (
                    <button
                      className="text-red-600 hover:text-red-700 flex items-center"
                      onClick={() => openModal(entity.EntityID)}
                    >
                      <MdOutlineDeleteForever className="h-6 w-6" />
                    </button>
                  )}
                </div>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          className="pagination-bar mt-4"
          currentPage={currentPage}
          totalCount={EntityMaster.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>

      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showModal}
        onConfirm={handleDelete}
        onCancel={closeModal}
      />
    </div>
  );
};

export default EntityMastermain;
