
import "./App.css";
import Login from "./Loginpage/Loginpage";
import SignUP from "./SignUP/SignUP";
import Home from "./Pages/Home";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Layout from "./components/Layout";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./Context/AuthContext";

function App() {
  const PrivateRoute = ({ element }) => {
    const { isAuthenticated } = useAuth();
    console.log("isAuthenticated : ", isAuthenticated);
    return isAuthenticated ? element : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
       
          <main>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/*"
                element={<PrivateRoute element={<Layout />} />}
              />
            </Routes>
          </main>
          {/* <Layout/> */}
        
      
    </BrowserRouter>
  );
}

export default App;









// import "./App.css";
// import Login from "./Loginpage/Loginpage";
// import SignUP from "./SignUP/SignUP";
// import Home from "./Pages/Home";
// import Header from "./components/Header";
// import Sidebar from "./components/Sidebar";
// import Layout from "./components/Layout";
// import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { useAuth } from "./Context/AuthContext";
// import { UserProvider } from "./Context/UserContext"; // Import UserProvider

// function App() {
//   const PrivateRoute = ({ element }) => {
//     const { isAuthenticated } = useAuth(true);
//     console.log("isAuthenticated : ", isAuthenticated);
//     return isAuthenticated ? element : <Navigate to="/login" />;
//   };

//   return (
//     <BrowserRouter>
//  {/* Wrap with UserProvider */}
//         <div className="flex">
//           <div className="flex-1">
//             <main>
//               <Routes>
//                 <Route path="/login" element={<Login />} />
//                 {/* <Route path="/signup" element={<SignUP />} /> */}
//                 <Route
//                   path="/*"
//                   element={<PrivateRoute element={<Layout />} />}
//                 />
//               </Routes>
//             </main>
//           </div>
//         </div>

//     </BrowserRouter>
//   );
// }

// export default App;
