import { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";

const CheckboxGrid = ({
  tableHeaders,
  grid,
  UserID,
  MainmenuId,
  MainmenuLinkId,
  onFetchTrigger,
}) => {
  const [isCheckAll, setIsCheckAll] = useState({
    IsAdd: false,
    IsEdit: false,
    IsDelete: false,
    IsView: false,
    IsExport: false,
  });
  const [list, setList] = useState(grid);

  console.log("UserID checkboxGrid:", UserID);

  console.log("Grid Data :", grid);

  // Use effect to update list when grid prop changes
  useEffect(() => {
    setList(grid);
  }, [grid]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Use axios to submit data
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_RightsHourUpdate`,
        {
          UserID: UserID,
          MainmenuId: MainmenuId,
          MainmenuLinkId: MainmenuLinkId,
          checkboxData: list,
        }
      )
      .then((response) => {
        console.log("Response:", response.data);
        if (response.status === 200) {
          message.success("Updated Successfully");
          onFetchTrigger(); // Trigger the fetch in RightsModal
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSelectAll = (column) => {
    const newIsCheckAll = { ...isCheckAll, [column]: !isCheckAll[column] };
    setIsCheckAll(newIsCheckAll);
    const newList = list.map((row) => ({
      ...row,
      [column]: newIsCheckAll[column],
    }));
    setList(newList);
  };

  const handleClick = (e, index, column) => {
    const newList = [...list];
    newList[index][column] = e.target.checked;
    setList(newList);

    // Update isCheckAll if all checkboxes in the column are checked
    const allChecked = newList.every((row) => row[column]);
    setIsCheckAll((prev) => ({
      ...prev,
      [column]: allChecked,
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="overflow-x-auto">
        <table className="table-auto w-full mt-5 md:mt-5">
          <thead className="text-center text-sm">
            <tr>
              <th className="px-2">{tableHeaders[1]}</th>
              {tableHeaders.slice(2, 7).map((header, idx) => (
                <th key={idx} className="px-2">
                  <input
                    type="checkbox"
                    onChange={() => handleSelectAll(header)}
                    checked={isCheckAll[header]}
                    className="mr-2"
                  />
                  {header.replace("Is", "")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.map((row, index) => (
              <tr key={index} className="text-center">
                <td className="px-2">{row.Description}</td>
                {tableHeaders.slice(2, 7).map((header, idx) => (
                  <td key={idx} className="px-2">
                    <input
                      type="checkbox"
                      checked={row[header]}
                      onChange={(e) => handleClick(e, index, header)}
                      className="mx-auto"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end my-8 ">
          <button
            type="submit"
            className=" p-2 rounded  bg-gray-800 text-white hover:bg-neutral-400 border-none hover:text-black px-6 btn-sm normal-case md:ml-4"
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default CheckboxGrid;
