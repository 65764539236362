import React, { useEffect, useState } from "react";
import TitleCard from "../Card/TitleCard";
import InputText from "../Input/InputText";
import { useAuth } from "../Context/AuthContext";
import axios from "axios";
const Seemorenotification = () => {
  const { userdata } = useAuth();
  const [fromDate, setFromDate] = useState(getOneWeekAgoDate());
  const [toDate, setToDate] = useState(getDate());
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputData, setInputData] = useState({
    UserID: userdata?.UserID || "",
    fromDate: fromDate,
    toDate: toDate,
    IsSeeMore :0 ,
  });

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        console.log("Fetching notifications with:", inputData); // Debugging log
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/AtlysNotificationData?UserID=${inputData.UserID}&IsSeeMore=${inputData.IsSeeMore}&fromDate=${inputData.fromDate}&toDate=${inputData.toDate}`
        ); 

        console.log("API Response:", response.data); // Log the entire response

        if (response.data.success) {
          setNotifications(response.data.data);
        } else {
          setError("Failed to fetch notifications.");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (inputData.UserID) {
      fetchNotifications();
    }
  }, [inputData]);

  if (loading)
    return <div className="text-center text-gray-500">Loading...</div>;
  if (error)
    return <div className="text-center text-red-500">Error: {error}</div>;

  const updateFormValue = (name, value) => {
    const formattedDateValue = formatDateFromInput(value);

    if (name === "fromDate") {
      setFromDate(formattedDateValue);
      setInputData((prevData) => ({
        ...prevData,
        FromDate: formattedDateValue,
      }));
    } else if (name === "toDate") {
      setToDate(formattedDateValue);
      setInputData((prevData) => ({
        ...prevData,
        ToDate: formattedDateValue,
      }));
    }
  };

  function formatDateToInput(dateStr) {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  }

  function formatDateFromInput(dateStr) {
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
  }

  function getDate() {
    const today = new Date();
    return formatDateFromInput(today.toISOString().split("T")[0]);
  }

  function getOneWeekAgoDate() {
    const today = new Date();
    today.setDate(today.getDate() - 7);
    return formatDateFromInput(today.toISOString().split("T")[0]);
  }

  return (
    <TitleCard title={"All Notifications"}>
      <form>
        <div className="flex flex-col md:flex-row justify-between gap-2">
          <InputText
            name="fromDate"
            type="date"
            updateFormValue={updateFormValue}
            labelTitle="From Date"
            value={formatDateToInput(fromDate)}
          />
          <InputText
            name="toDate"
            type="date"
            updateFormValue={updateFormValue}
            labelTitle="To Date"
            value={formatDateToInput(toDate)}
          />
          <button
            type="button"
            className="px-6 py-2 rounded bg-gray-800 text-white hover:bg-neutral-400 border-none my-7 hover:text-black btn-sm normal-case"
            onClick={() => {
              setInputData((prevData) => ({
                ...prevData,
                FromDate: fromDate,
                ToDate: toDate,
              }));
            }}
          >
            Search
          </button>
        </div>
      </form>
      <div className="mt-4 max-h-[400px] overflow-y-auto  rounded-lg shadow-lg">
        {notifications.map((notification) => (
          <div
            key={notification.SysID}
            className="bg-white p-4 border border-blue-300 my-2 rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
              {/* <div className="flex-shrink-0">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios/50/envelope-dots.png"
                  alt="envelope-dots"
                />

          
              </div> */}
            <div className="flex-1">
                <div className="text-blue-600 text-sm mb-1">
                  {notification.Entity}
                </div>
                <div className="text-gray-800 text-sm  mb-1 md:overflow-hidden">
                  {notification.Remarks}
                </div>
                <div className="text-gray-400 text-xs text-right">
                  {new Date(
                    notification.NotificationDateTime
                  ).toLocaleDateString()}
                </div>
              </div>
          </div>
        ))}
      </div>
    </TitleCard>
  );
};

export default Seemorenotification;






