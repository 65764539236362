import React, { useState } from "react";
import axios from "axios";
import InputText from "../Input/InputText";
import TextAreaInput from "../Card/TextAreaInput";
import TitleCard from "../Card/TitleCard";
import { message } from "antd";

import {
  VALIDATION_ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  Messages,
} from "../message.js";

const NewNotsReminder = ({ user }) => {
  const [inputData, setInputData] = useState({
    Type: "NewNote/Reminder",
    Title: "",
    Note: "",
    Upload: "",
    ReminderDate: "",
    EmailID: "",
    EntityID: user.EntityID,
  });

  const [errors, setErrors] = useState({});
 // const [message, setMessage] = useState("");
  //const [showMessage, setShowMessage] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }
  };

  const updateFormValue = (name, value) => {
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  // const validateForm = () => {
  //   const newErrors = {};

  //   if (!inputData.Title) newErrors.Title = "Title is required";
  //   if (!inputData.Note) newErrors.Note = "Note is required";
  //   if (!inputData.EmailID || !/\S+@\S+\.\S+/.test(inputData.EmailID))
  //     newErrors.EmailID = "A valid Email ID is required";
  //   if (!inputData.ReminderDate)
  //     newErrors.ReminderDate = "Reminder Date is required";

  //   setErrors(newErrors);

  //   return Object.keys(newErrors).length === 0;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!validateForm()) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/AtlysinsertmeetingSchedules`,
        inputData
      );
      console.log("inputData submit create meeting :", inputData);
      message.success(Messages.Messages_Note);
      //setShowMessage(true);
      setInputData({
        Type: "NewNote/Reminder",
        Title: "",
        Note: "",
        Upload: "",
        ReminderDate: "",
        EmailID: "",
        EntityID: user.EntityID,
      });
    } catch (error) {
      console.error("There was an error!", error);
      message.error(Messages.Messages_Meeting_Error);
      //setShowMessage(true);
    }
  };

  // const handleCloseMessage = () => {
  //   setShowMessage(false);
  // };

  return (
    <div>
      <TitleCard  title={"New Note/Reminder"}>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1">
            <InputText
              name="Title"
              value={inputData.Title}
              updateFormValue={updateFormValue}
              labelTitle="Title"
              error={errors.Title}
            />
            <TextAreaInput
              name="Note"
              value={inputData.Note}
              updateFormValue={updateFormValue}
              labelTitle="Note"
              error={errors.Note}
            />
            <InputText
              type="date"
              name="ReminderDate"
              value={inputData.ReminderDate}
              updateFormValue={updateFormValue}
              labelTitle="Reminder Date"
              error={errors.ReminderDate}
            />
            <InputText
              type="email"
              name="EmailID"
              value={inputData.EmailID}
              updateFormValue={updateFormValue}
              labelTitle="Email ID"
              error={errors.EmailID}
            />

            <main className="flex items-center justify-center font-sans">
              <label
                htmlFor="dropzone-file"
                className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-gray-600 bg-white p-6 text-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 text-blue-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>

                <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">
                  Supportive
                </h2>

                <p className="mt-2 text-gray-500 tracking-wide">
                  Upload or drag & drop your file SVG, PNG, JPG or GIF.
                </p>

                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                />

                {previewUrl && (
                  <div className="mt-4">
                    <img
                      src={previewUrl}
                      alt="Preview"
                      className="max-w-full max-h-64 rounded-md border border-gray-300"
                    />
                  </div>
                )}
              </label>
            </main>

            <div className="relative  text-end ">
              <button
                type="submit" // Fixed button type
                className=" bottom right-0 text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800 shadow-lg shadow-gray-500/50 dark:shadow-lg dark:shadow-gray-800/80 font-medium rounded-lg text-sm px-5 py-3 my-3 text-center me-2 mb-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </TitleCard>
      {/* {showMessage && message && (
        <div
          className={`mt-4 p-4 ${
            message.includes("error")
              ? "bg-red-200 text-red-800"
              : "bg-green-200 text-green-800"
          } border rounded relative`}
        >
          <button
            onClick={handleCloseMessage}
            className="absolute top-2 right-2 text-lg font-bold"
          >
            &times;
          </button>
          {message}
        </div>
      )} */}
    </div>
  );
};

export default NewNotsReminder;
