
import React from "react";

const ConfirmationDeleteModal = ({ showModal, onConfirm, onCancel }) => {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0  bg-gray-500  bg-opacity-75 flex items-center justify-center">
      <div className="bg-white px-6 pt-6 pb-2 rounded shadow-xl border border-slate-300">
        <h2 className="text-lg  text-center  font-bold ">Confirm Deletion</h2>
        <p className="mb-4">Are you sure you want to delete this ?</p> <hr />
        <div className="flex justify-between px-8">
          <button
             className=" text-red-600 px-4 py-2 rounded mr-2"
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            className=" btn-secondary"
            onClick={onCancel}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDeleteModal;
