import React, { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../components/Pagination";
import LogonHoursModal from "../Modal/UserMasterGroup-LogonHours";
import "react-responsive-modal/styles.css";
import "../ModalCSS.css";
import RightsModal from "../Modal/UserMasterGroup-Rights";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDeleteForever } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { FaUserCheck } from "react-icons/fa";
import { FaRegHourglass } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { decode } from "../utils";
import { VALIDATION_ERROR_MESSAGES, SUCCESS_MESSAGES , Messages} from "../message.js";
const CryptoJS = require("crypto-js");
const PageSize = 10;
const secretKey = process.env.REACT_APP_SECRET_KEY;

function UserMasterGroupMain({ changeForms }) {
  const [Grid, setGrid] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [LogonHoursOpen, setLogonHoursOpen] = useState(false);
  const [hourGrid, setHourGrid] = useState([]);
  const [selectedGroupID, setSelectedGroupID] = useState(null);
  const [SearchQuery, setSearchquery] = useState("");
  const [rightsarr, setRightsarr] = useState([]);
  const { id } = useParams();

  
  useEffect(() => {
    if (id) {
      const decrypt = decode(id);
      const decryptedData = JSON.parse(decrypt.Rights);
      setRightsarr(decryptedData);
    }
  }, [id]);

  const onOpenModal = (GroupID) => {
    setSelectedGroupID(GroupID);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const handleLogonHours = (GroupID) => {
    setSelectedGroupID(GroupID);
    setLogonHoursOpen(true);
  };

  const onCloseLogonHoursModal = () => {
    setLogonHoursOpen(false);
  };

  // Edit button api to populate BranchID data in the form

  const handleEdit = async (GroupID, changeForms) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_Populate/${GroupID}`
        )
        .then((res) => {
          const bytes = CryptoJS.AES.decrypt(res.data, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
console.log('JSON.parse(decryptedData):',JSON.parse(decryptedData))
          setSelectedRowData(JSON.parse(decryptedData));
          changeForms(JSON.parse(decryptedData)[0]);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Get Data From api to show in table

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/UserGroupMaster_Grid`)
      .then((res) => {
        console.log("res.data UserGroupMaster_Grid : ", res.data);
        // const Decrypt = CryptoJS.AES.decrypt(res.data, secretKey);
        // const decryptedData = Decrypt.toString(CryptoJS.enc.Utf8);
        // setGrid(JSON.parse(decryptedData));
        setGrid(res.data);
        //   console.log(JSON.parse(decryptedData));
      });
  }, []);

  const filteredData = Grid.filter(
    (item) =>
      (item.GroupID && item.GroupID.toString().includes(SearchQuery)) ||
      (item.GroupCode &&
        item.GroupCode.toLowerCase().includes(SearchQuery.toLowerCase())) ||
      (item.GroupName &&
        item.GroupName.toLowerCase().includes(SearchQuery.toLowerCase()))
  );

  // Pagination

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = filteredData.slice(firstPageIndex, lastPageIndex);

  const ChangeNewCompanyMaster = () => {
    changeForms();
  };

  return (
    <div>
    <div className="flex flex-col md:flex-row justify-between mb-4 mt-4 gap-3">
      <input
        placeholder="Search......"
        type="text"
        className={` border-slate-300
          "cursor-not-allowed" : "cursor-default"
         rounded-md h-10 p-0 pl-3 border-2   sm:mt-2 w-full md:w-1/2 lg:w-1/3 `}
        // Assuming `SearchQuery` state is managed outside this snippet
      />
       {rightsarr[1] !== 0 && (
         <button
         className="btn bg-gray-800 text-white hover:bg-neutral-400 border-none hover:text-black px-6 btn-sm normal-case md:ml-4 flex items-center"
         onClick={()=>{changeForms()}}
       >
         <IoIosAdd className="text-white h-6 w-6 mr-2" />
         New
       </button>
        )}

      
    </div>

    <div className="overflow-x-auto w-full">
      <table className="table w-full mt-5 md:mt-5">
        <thead className="bg-gray-100 text-left">
          <tr className="text-gray-500">
            <th className="text-sm py-2 px-4">GroupID</th>
            <th className="text-sm py-2 px-4">GroupCode</th>
            <th className="text-sm py-2 px-4">GroupName</th>
            <th className="text-sm py-2 px-4">Action</th>
            <th className="text-sm py-2 px-4">Rights</th>
            <th className="text-sm py-2 px-4">Logon Hours</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-center">
          {currentTableData.map((l) => (
            <tr key={l.GroupID} className="text-left">
              <td className="px-4 py-4 whitespace-nowrap text-sm">{l.GroupID}</td>
              <td className="px-4 py-4 whitespace-nowrap text-sm">{l.GroupCode}</td>
              <td className="px-4 py-4 whitespace-nowrap text-sm">{l.GroupName}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm ">
                

                {rightsarr[2] !== 0 && (
                    <button
                    className="text-green-400 hover:text-green-700 flex items-center"
                    onClick={() => handleEdit(l.GroupID, changeForms)}
                  >
                    <AiOutlineEdit className="h-6 w-6" />
                  
                  </button>
                  )}

              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm  ">
                <button
                  className="text-blue-600 hover:text-blue-800 flex items-center"
                  onClick={() => onOpenModal(l.GroupID)}
                >
                  <FaUserCheck className="h-6 w-6" />
                </button>
              </td>
              <td className="px-10 py-4 whitespace-nowrap text-sm ">
                <button
                  className="text-red-600 hover:text-red-800 flex items-center"
                  onClick={() => handleLogonHours(l.GroupID)}
                >
                  <FaRegHourglass className="h-6 w-6" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        {open && (
          <RightsModal
            open={open}
            onCloseModal={onCloseModal}
            UserID={selectedGroupID} // Ensure `selectedGroupID` is managed in your state
          />
        )}

        {LogonHoursOpen && (
          <LogonHoursModal
            open={LogonHoursOpen}
            onCloseModal={onCloseLogonHoursModal}
            GroupID={selectedGroupID} // Ensure `selectedGroupID` is managed in your state
          />
        )}
      </div>
    </div>

    <Pagination
      className="pagination-bar mt-4"
      currentPage={currentPage}
      totalCount={Grid.length}
      pageSize={PageSize}
      onPageChange={(page) => setCurrentPage(page)}
    />
  </div>
  );
}
export default UserMasterGroupMain;
