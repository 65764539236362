import React, { useEffect, useState } from "react";
import InputText from "../Input/InputText";
import TextAreaInput from "../Card/TextAreaInput";
import Dropdown from "../Input/DropDown";
import axios from "axios";
import Select from "react-select";
import { message } from "antd";
import { useAuth } from "../Context/AuthContext";
import { Messages } from "../message.js";
import AutocompleteDropdown from "../components/AutocompleteDropdown.js";
const OrganizationForm = ({ changeForms, selectedRowData }) => {
  const { userdata } = useAuth();
  const [inputdata, setInputData] = useState({
    ...selectedRowData.pagedetails,
    // RepresentativesID: selectedRowData.Representatives[0] || [],
    RepresentativesID:
      Array.isArray(selectedRowData.Representatives) &&
      selectedRowData.Representatives.length > 0
        ? [selectedRowData.Representatives[0].EntityID]
        : [],
    // SolutionsID: selectedRowData.solutions||[],
    SolutionsID:
      Array.isArray(selectedRowData.solutions) &&
      selectedRowData.solutions.length > 0
        ? [selectedRowData.solutions[0].CMID]
        : [],
    Created_By: userdata.UserID ? userdata.UserID : null,
    Modified_By: userdata.UserID ? userdata.UserID : null,
  }||{});
console.log('selectedRowData OrganizationForm:',selectedRowData);
  const [typeDropdownData, setTypeDropdownData] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);

  const [checkboxes, setCheckboxes] = useState({
    IsVisaSolutionsOutsourced: inputdata.IsVisaSolutionsOutsourced || false,
    IsScopeOfferSkylaneSolutions:
      inputdata.IsScopeOfferSkylaneSolutions || false,
    IsPossibilityToExtend: inputdata.IsPossibilityToExtend || false,
    IsOutsourcedViaTender: inputdata.IsOutsourcedViaTender || false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let url, method;

      if (selectedRowData.pagedetails && selectedRowData.pagedetails.OrgID) {
        url = `${process.env.REACT_APP_API_BASE_URL}/updateOrganization/${selectedRowData.pagedetails.OrgID}`;
        method = `PUT`;
      } else {
        url = `${process.env.REACT_APP_API_BASE_URL}/organization`;
        method = `POST`;
      }
      const response = await axios({
        method: method,
        url: url,
        // data: inputdata,
        data: { ...inputdata, ...checkboxes },
      });
      console.log("BindinsertOrganizationatlys", inputdata);
      if (selectedRowData.pagedetails && selectedRowData.pagedetails.OrgID) {
        message.success(Messages.Messages_Successfully_Edited);
      } else {
        message.success(Messages.Messages_Successfully);
        e.target.reset(); // Reset the form after successful submission
      }

      handleBack();
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      message.error(Messages.Messages_submit_error);
    }
  };

  const updateFormValue = (name, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [name]: typeof value === "string" ? value : Boolean(value), // Ensure boolean value
    }));
  };

  useEffect(() => {
    fetchData();
    //  fetchTypeData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/GetBindDropdownForCountry`
      );
      setDropdownData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 

  const handleBack = () => {
    changeForms();
  };

  const handleDropdownChangeselectedOption = (selectedOption) => {
    setInputData({
      ...inputdata,
      RepresentativesID: selectedOption
        ? selectedOption.map((option) => option.EntityID)
        : [], 
    });
  };
  const handleDropdownChangeTypeOfSolutionsOffered = (selectedOptions) => {
    setInputData({
      ...inputdata,
      SolutionsID: selectedOptions.map((option) => option.CMID), 
    });
  };
  const handleDropdownChange = (e, selectedOption) => {
    const { name, value } = e.target;
    setInputData((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
    setInputData((prevData) => ({
      ...prevData,
      [name]: typeof value === "string" ? value : String(value),
    }));
    // setInputData({
    //   ...inputdata,
    //   RepresentativesID: selectedOption ? selectedOption.EntityID : null, // Adjust according to your data structure
    // });
    // if (errors[name]) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [name]: "",
    //   }));
    // }
  };

  const SolutioncheckHandle = () => {
    setPossibilitytoextend(!Possibilitytoextend);
  };
  const SolutioncheckHandlehandleCheckboxChange = (e) => {
    SolutioncheckHandle();
    updateFormValue(e.target.name, e.target.checked);
  };

  const [Possibilitytoextend, setPossibilitytoextend] = useState(false);

  const UserCategorydrop = [
    { Text: "1", Value: "1" },
    { Text: "2", Value: "2" },
    { Text: "3", Value: "3" },
  ];

  const [tendering, settendering] = useState(false);

  const tenderingcheckHandle = () => {
    settendering(!tendering);
  };
  const tenderingcheckHandlehandleCheckboxChange = (e) => {
    tenderingcheckHandle();
    updateFormValue(e.target.name, e.target.checked);
  };

  const tenderingUserFields = () => {
    if (tendering || inputdata.IsOutsourcedViaTender) {
      return (
        <div className="">
          <Dropdown
            type=""
            options={data.tenderTypes}
            name="TenderingThrough"
            labelTitle="Tendering through"
            value={inputdata.TenderingThrough}
            updateFormValue={updateFormValue}
            labelText={"CMDescription"}
            labelValue={"CMID"}
            onChange={handleDropdownChange}
          />
        </div>
      );
    }
    return null;
  };

  const [IsScopeFields, setIsScopeFields] = useState(false);
  const IsScopeFieldscheckHandle = () => {
    setIsScopeFields(!IsScopeFields);
  };
  const tIsScopeFieldscheckHandleCheckboxChange = (e) => {
    IsScopeFieldscheckHandle();
    updateFormValue(e.target.name, e.target.checked);
  };

  const [data, setData] = useState({
    entityTypes: [],
    tenderTypes: [],
    solutionTypes: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/OrganizationMasterDDL`
        );
        setData(response.data);

        console.log("setData,", response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [EntityMaster, setEntityMaster] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/BindGridAtlysEnityMaster`
        );
        setEntityMaster(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // const handleDropdownChange = (selectedOption) => {
  //   setInputdata({
  //     ...inputdata,
  //     RepresentativesID: selectedOption ? selectedOption.EntityID : null, // Adjust according to your data structure
  //   });
  // };

  const selectedRepresentatives = Array.isArray(inputdata.RepresentativesID[0])
    ? inputdata.RepresentativesID
    : [];
  console.log(
    "RepresentativesID:",
    inputdata.RepresentativesID,
    "Type:",
    typeof inputdata.RepresentativesID
  );

  return (
    <div>
      <div>
         
      </div>
      <form onSubmit={handleSubmit} className="mx-auto ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Source Country Dropdown */}
          <div className="col-span-1">
            <Dropdown
              name="SourceCountry"
              labelTitle="Source Country"
              value={inputdata.SourceCountry}
              updateFormValue={updateFormValue}
              onChange={handleDropdownChange}
              options={dropdownData || []}
              labelText="CountryName"
              labelValue="CountryID"
            />
          </div>

          {/* City Input */}
          <div className="col-span-1">
            <InputText
              name="City"
              labelTitle="City"
              defaultValue={inputdata.City}
              updateFormValue={updateFormValue}
            />
          </div>

          {/* Client Country Dropdown */}
          <div className="col-span-1">
            <Dropdown
              name="ClientCountry"
              labelTitle="Client Country (Destination)"
              value={inputdata.ClientCountry}
              updateFormValue={updateFormValue}
              onChange={handleDropdownChange}
              options={dropdownData || []}
              labelText="CountryName"
              labelValue="CountryID"
            />
          </div>

          {/* Client Type Dropdown */}
          <div className="col-span-1">
            <Dropdown
              name="ClientType"
              labelTitle="Client Type"
              value={inputdata.ClientType}
              updateFormValue={updateFormValue}
              onChange={handleDropdownChange}
              options={data.entityTypes}
              labelText="CMDescription"
              labelValue="CMID"
            />
          </div>

          {/* Address Input */}
          <div className="col-span-1">
            <InputText
              name="Address"
              labelTitle="Address"
              defaultValue={inputdata.Address}
              updateFormValue={updateFormValue}
            />
          </div>

          {/* National Day Input */}
          <div className="col-span-1">
            <InputText
              type="date"
              name="NationalDay"
              labelTitle="National Day"
              value={inputdata.NationalDay}
              updateFormValue={updateFormValue}
            />
          </div>

          {/* Checkbox for Scope to Offer Skylane Solutions */}
          {/* <div className="col-span-1 flex items-center">
            <input
              name="IsScopeOfferSkylaneSolutions"
              type="checkbox"
              className="mx-2"
              Value={inputdata.IsScopeOfferSkylaneSolutions}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label>Scope to offer Skylane solutions</label>
          </div> */}
          <div className="col-span-1 flex items-center">
            <input
              name="IsVisaSolutionsOutsourced"
              type="checkbox"
              className="mx-2"
              checked={checkboxes.IsVisaSolutionsOutsourced}
              onChange={handleCheckboxChange}
            />
            <label> Visa Solutions Out sourced</label>
          </div>

          {/* Fields for Skylane Solutions */}
          {/* {isChecked */}
          {checkboxes.IsVisaSolutionsOutsourced && (
            <div className="col-span-1 flex flex-col gap-4">
              <InputText
                name="NameOfServiceProvider"
                labelTitle="Service Provider"
                value={inputdata.NameOfServiceProvider}
                updateFormValue={updateFormValue}
              />
            </div>
          )}
          {checkboxes.IsVisaSolutionsOutsourced && (
            <div className="col-span-1 flex flex-col gap-4">
              <InputText
                type="date"
                name="CurrentContractValidityPeriod"
                labelTitle="Current Contract Validity Period"
                value={inputdata.CurrentContractValidityPeriod}
                updateFormValue={updateFormValue}
              />
            </div>
          )}
          {console.log("inputdata :", inputdata)}
          {checkboxes.IsVisaSolutionsOutsourced && (
            <div className="col-span-1 flex items-center my-4">
              <input
                name="IsPossibilityToExtend"
                type="checkbox"
                className="mx-2"
                defaultValue={inputdata.IsPossibilityToExtend}
                checked={checkboxes.IsPossibilityToExtend}
                onChange={handleCheckboxChange}
              />
              <label className="text-sm">Possibility to extend</label>
            </div>
          )}

          {checkboxes.IsPossibilityToExtend && (
            <div className="col-span-1">
              <Dropdown
                onChange={handleDropdownChange}
                options={UserCategorydrop}
                name="ExtensionPeriod"
                value={inputdata.ExtensionPeriod}
                labelTitle="Extension Period - How many years"
                updateFormValue={updateFormValue}
              />
            </div>
          )}

          {/* Checkbox for Outsourced via Tender */}
          <div className="col-span-1 flex items-center">
            <input
              name="IsOutsourcedViaTender"
              type="checkbox"
              className="mx-2"
              value={inputdata.IsOutsourcedViaTender}
              checked={checkboxes.IsOutsourcedViaTender}
              onChange={handleCheckboxChange}
            />
            <label>Outsourced via tender</label>
          </div>

          {checkboxes.IsOutsourcedViaTender && (
            <div className="my-2 w-full">
              <Dropdown
                name="TenderingThrough"
                labelTitle={"TenderingThrough"}
                value={inputdata.TenderingThrough}
                updateFormValue={updateFormValue}
                onChange={handleDropdownChange}
                options={data.tenderTypes}
                labelText="CMDescription"
                labelValue="CMID"
              />

              {/* <div className="">
                <AutocompleteDropdown
                  options={data.solutionTypes}
                  onChange={handleDropdownChangeTypeOfSolutionsOffered}
                  defaultValue={selectedRowData.TypeOfSolutionsOffered}
                  labelTitle="Type of solutions to be offered"
                  labelValue="CMID" // Key for option value
                  labelText="CMDescription" // Key for option label
                />
              </div> */}
            </div>
          )}

          {/* Tendering User Fields */}
          {/* <div className="col-span-1">{tenderingUserFields()}</div> */}

          {/* Jurisdiction Input */}
          <div className="col-span-1">
            <InputText
              name="Jurisdiction"
              labelTitle="Jurisdiction"
              value={inputdata.Jurisdiction}
              updateFormValue={updateFormValue}
            />
          </div>

          <div className="col-span-1 flex items-center">
            <input
              name="IsScopeOfferSkylaneSolutions"
              type="checkbox"
              className="mx-2"
              value={inputdata.IsScopeOfferSkylaneSolutions}
              checked={checkboxes.IsScopeOfferSkylaneSolutions}
              onChange={handleCheckboxChange}
            />
            <label>Scope Offer Skylane Solutions</label>
          </div>

          {checkboxes.IsScopeOfferSkylaneSolutions && (
            <div className="my-2 w-full">
              <div className="">
                <AutocompleteDropdown
                  options={data.solutionTypes}
                  onChange={handleDropdownChangeTypeOfSolutionsOffered}
                  defaultValue={selectedRowData.solutions}
                  labelTitle="Type of solutions to be offered"
                  labelValue="CMID"
                  labelText="CMDescription"
                />
              </div>
            </div>
          )}

          <div className="col-span-1">
            <InputText
              type="number"
              name="ApproxApplicantVolumes"
              labelTitle="Approx Applicant Volumes (annual)"
              value={inputdata.ApproxApplicantVolumes}
              updateFormValue={updateFormValue}
            />
          </div>
          {/* <div className="col-span-1 flex items-center">
            <input
              name="IsScopeOfferSkylaneSolutions"
              type="checkbox"
              className="mx-1"
              defaultChecked={inputdata.IsScopeOfferSkylaneSolutions}
              onChange={tIsScopeFieldscheckHandleCheckboxChange}
            />
            <label>Scope to offer Skylane solutions</label>
          </div> */}

          {/* <div className="col-span-1">{IsScopeUserFields()}</div> */}
          {/* Representatives Autocomplete Dropdown */}
          <div className="col-span-1 w-full my-2">
            <AutocompleteDropdown
              options={EntityMaster}
              onChange={handleDropdownChangeselectedOption}
              defaultValue={selectedRowData.Representatives}
              labelTitle="Representatives"
              labelValue="EntityID"
              labelText="Name"
            />
          </div>
        </div>

        {/* Buttons for Navigation */}
        <div className="flex flex-col md:flex-row justify-between mt-6 gap-4">
          <button
            onClick={handleBack}
            className="btn w-full md:w-24  hover:bg-gray-200 rounded px-4 py-2"
          >
            Back
          </button>
          <button
            type="submit"
            className="btn w-full md:w-24 bg-gray-800 text-white hover:bg-gray-700 rounded px-4 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationForm;
