// import React, { useEffect, useRef, useState } from "react";
// import { useNavigate, useLocation, Link } from "react-router-dom";
// import axios from "axios";

// import TitleCard from "../Card/TitleCard";
// import InputText from "../Input/InputText";
// import { MdOutlineDashboardCustomize } from "react-icons/md";
// import Dropdown from "../Input/DropDown"; // Ensure you have a Dropdown component
// import share from "../Icons/share_3857028.png";
// import { useAuth } from "../Context/AuthContext";
// import OrganizationForm from "../MasterFrom/OrganizationForm";
// import EntityMasterForm from "../MasterFrom/EntityMasterForm";
// import Representativesicon from "../Icons/representative.png";
// import { BsThreeDotsVertical } from "react-icons/bs";
// import { motion } from "framer-motion";
// import { SiAwsorganizations } from "react-icons/si";
// const Organizationpage = () => {
//   const selectedRowData = {};
//   const { userdata } = useAuth();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [Representatives, setRepresentatives] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [showOrgForm, setShowOrgForm] = useState(false);
//   const [showEntityForm, setShowEntityForm] = useState(false);
//   const [fromDate, setFromDate] = useState(getOneWeekAgoDate());
//   const [toDate, setToDate] = useState(getDate());
//   const [selectedEmployee, setSelectedEmployee] = useState("");
//   const [searchQuery, setSearchQuery] = useState("");
//   const [showDropdown, setShowDropdown] = useState(false); // State for dropdown visibility
//   const dropdownRef = useRef(null);

//   const toggleDropdown = () => {
//     setShowDropdown((prev) => !prev);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       console.log("Clicked element:", event.target);

//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         console.log("Closing dropdown");
//         setShowDropdown(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);
//   useEffect(() => {
//     const fetchRepresentatives = async () => {
//       try {
//         const res = await axios.get(
//           `${process.env.REACT_APP_API_BASE_URL}/BindGridAtlysEnityMaster`
//         );
//         setRepresentatives(res.data);
//       } catch (error) {
//         console.error("Failed to fetch representatives:", error);
//       }
//     };

//     const fetchAllUserData = async () => {
//       setLoading(true);
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_API_BASE_URL}/getOrganizationCardInfo`,
//           {
//             Representatives: "",
//             ShowMore: "false",
//             FromDate: fromDate,
//             ToDate: toDate,
//             FilterCriteria: "",
//           }
//         );
//         setUsers(response.data.data || []);
//         setFilteredUsers(response.data.data || []);
//       } catch (err) {
//         setError("Failed to fetch user data");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchRepresentatives();
//     fetchAllUserData();
//   }, [fromDate, toDate]);

//   useEffect(() => {
//     if (location.state) {
//       const { UserID, fromDate, ToDate } = location.state;
//       setSelectedEmployee(UserID);
//       if (ToDate) setToDate(ToDate);
//       if (fromDate) setFromDate(fromDate);
//     }
//   }, [location.state]);

//   const handleDropdownChange = (e) => {
//     const selectedValue = e.target.value;
//     setSelectedEmployee(selectedValue);
//     if (selectedValue) {
//       const filtered = users.filter((user) => user.EntityID === selectedValue);
//       setFilteredUsers(filtered);
//     } else {
//       setFilteredUsers(users);
//     }
//   };

//   const handleLeadPreview = (user) => {
//     navigate("/Organizationpage/OrgCardpage", {
//       state: { user, toDate, Representatives, fromDate, orgId: user.OrgID },
//     });
//   };

//   const handleSearch = (e) => {
//     const query = e.target.value.toLowerCase();
//     setSearchQuery(query);
//     const filtered = users.filter(
//       (user) =>
//         (user.Title || "").toLowerCase().includes(query) ||
//         (user.NameOfServiceProvider || "").toLowerCase().includes(query) ||
//         (user.SourceCountry || "").toLowerCase().includes(query) ||
//         (user.EntityName || "").toLowerCase().includes(query)
//     );
//     setFilteredUsers(filtered);
//   };

//   function formatDateToInput(dateStr) {
//     const [day, month, year] = dateStr.split("-");
//     const monthIndex = new Date(`${month} 1, 2000`).getMonth() + 1;
//     const monthFormatted = monthIndex.toString().padStart(2, "0");
//     return `${year}-${monthFormatted}-${day}`;
//   }

//   function formatDateFromInput(dateStr) {
//     const [year, month, day] = dateStr.split("-");
//     const monthName = new Date(`${year}-${month}-${day}`)
//       .toLocaleString("en-US", { month: "short" })
//       .toLowerCase();
//     return `${day}-${monthName}-${year}`;
//   }

//   function getDate() {
//     const today = new Date();
//     return formatDateFromInput(today.toISOString().split("T")[0]);
//   }

//   function getOneWeekAgoDate() {
//     const today = new Date();
//     today.setDate(today.getDate() - 7);
//     return formatDateFromInput(today.toISOString().split("T")[0]);
//   }

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>{error}</p>;

//   return (
//     <TitleCard>
//       <div className="flex justify-between">
//         <div className="flex-grow"></div>{" "}
//         {/* This will take up available space */}
//         <div className="relative">
//           <button
//             className="text-gray-800 font-bold py-2 px-3 sm:px-4 rounded-full inline-flex items-center"
//             onClick={toggleDropdown}
//           >
//             <BsThreeDotsVertical className="w-6 h-6 text-black" />
//           </button>
//           {showDropdown && (
//             <motion.div
//               className=""
//               initial={{ opacity: 0, y: -10 }}
//               animate={{ opacity: 1, y: 0 }}
//               exit={{ opacity: 0, y: -10 }}
//               transition={{ duration: 0.2 }}
//             >
//               <div className="absolute right-2 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
//                 {userdata?.IsSuperUser !== "0" && (
//                   <Link
//                     to="/Dashboard"
//                     className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
//                   >
//                     <div className="flex items-center gap-2">
//                       <MdOutlineDashboardCustomize />
//                       <p>Dashboard</p>
//                     </div>
//                   </Link>
//                 )}
//                 <button
//                   className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
//                   // onClick={() => setShowOrgForm(true)}
//                   onClick={() => {
//                     setShowOrgForm(true); // Show Organization Form
//                     setShowEntityForm(false); // Hide Entity Form
//                   }}
//                 >
//                   <div className="flex items-center gap-2">
//                     <SiAwsorganizations />
//                     <p>Organization</p>
//                   </div>
//                 </button>
//                 <button
//                   className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
//                   // onClick={() => setShowEntityForm(true)}
//                   onClick={() => {
//                     setShowEntityForm(true); // Show Entity Form
//                     setShowOrgForm(false); // Hide Organization Form
//                   }}
//                 >
//                   <div className="flex items-center gap-2">
//                     <img
//                       src={Representativesicon}
//                       className="w-4 h-4 inline"
//                       alt="Representatives"
//                     />
//                     <p>Representatives</p>
//                   </div>
//                 </button>
//               </div>
//             </motion.div>
//           )}
//         </div>
//       </div>
//       <div>
//         {showOrgForm ? (
//           <div>
//             <h1 className="text-xl font-semibold">Organization</h1>
//             <OrganizationForm
//               changeForms={() => setShowOrgForm(false)}
//               selectedRowData={selectedRowData}
//             />
//           </div>
//         ) : showEntityForm ? (
//           <div>
//             <h1 className="text-xl font-semibold">Representatives</h1>
//             <EntityMasterForm changeForms={() => setShowEntityForm(false)} />
//           </div>
//         ) : (
//           <div>
//             <div className="flex flex-col md:flex-row justify-between gap-2 mt-4">
//               <Dropdown
//                 name="Representative"
//                 labelTitle="Representative"
//                 value={selectedEmployee}
//                 options={Representatives || []}
//                 labelText="Name"
//                 labelValue="EntityID"
//                 onChange={handleDropdownChange}
//               />
//               <InputText
//                 name="FromDate"
//                 type="date"
//                 updateFormValue={(name, value) =>
//                   setFromDate(formatDateFromInput(value))
//                 }
//                 labelTitle="From Date"
//                 value={formatDateToInput(fromDate)}
//               />
//               <InputText
//                 name="ToDate"
//                 type="date"
//                 updateFormValue={(name, value) =>
//                   setToDate(formatDateFromInput(value))
//                 }
//                 labelTitle="To Date"
//                 value={formatDateToInput(toDate)}
//               />
//             </div>

//             {/* Search Input */}
//             <div className="mt-4">
//               <input
//                 type="text"
//                 placeholder="Search Users..."
//                 value={searchQuery}
//                 onChange={handleSearch}
//                 className="border-slate-300 rounded-md h-10 p-0 pl-3 border-2 sm:mt-2 w-full my-2"
//               />
//             </div>

//             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 mt-4">
//               {filteredUsers.map((user) => (
//                 <div
//                   onClick={() => handleLeadPreview(user)}
//                   key={user.OrgID}
//                   className="bg-white cursor-pointer dark:bg-slate-800 rounded-lg shadow-md hover:shadow-xl hover:scale-105 ease-out duration-100 overflow-hidden border"
//                 >
//                   <figure className="relative p-3 flex justify-between">
//                     <div className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-lg">
//                       <div className="text-gray-600 hover:text-gray-900 dark:text-gray-300 cursor-pointer">
//                         <img src={share} alt="" className="h-4" />
//                       </div>
//                     </div>
//                     <div>
//                       <div className="flex gap-4">
//                         <div>
//                           <div className="text-lg font-semibold text-slate-900 dark:text-slate-200">
//                             {user.Title || "Not Available"}
//                           </div>
//                           <div className="text-slate-600 dark:text-slate-300 text-xs">
//                             {user.SubHeading || "NA"}
//                           </div>
//                         </div>
//                       </div>

//                       <div className="flex justify-between flex-col">
//                         <div className="mt-2">
//                           <div className="flex gap-2 text-xs p-1">
//                             <p>Source Country</p>
//                             <div className="text-xs text-gray-500">
//                               {user.SourceCountry || "NA"}
//                             </div>
//                           </div>
//                           <div className="flex gap-2 text-xs p-1">
//                             <p>City</p>
//                             <div className="text-xs text-gray-500">
//                               {user.City || "NA"}
//                             </div>
//                           </div>
//                           <div className="flex gap-2 text-xs p-1">
//                             <p>Visas outsourced </p>
//                             <div className="text-xs text-gray-500">
//                               {user.IsVisaSolutionsOutsourced || "NA"}
//                             </div>
//                           </div>
//                           <div className="flex gap-2 text-xs p-1">
//                             <p>Name Of Service Provider</p>
//                             <div className="text-xs text-gray-500">
//                               {user.NameOfServiceProvider || "NA"}
//                             </div>
//                           </div>
//                           <div className="flex gap-2 text-xs p-1">
//                             <p>Contract Validity Period</p>
//                             <div className="text-xs text-gray-500">
//                               {user.CurrentContractValidityPeriod || "NA"}
//                             </div>
//                           </div>
//                           <div className="flex gap-2 text-xs p-1">
//                             <p>Name of Representatives</p>
//                             <div className="text-xs text-gray-500">
//                               {user.EntityName || "NA"}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </figure>
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}
//       </div>
//     </TitleCard>
//   );
// };

// export default Organizationpage;



import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import TitleCard from "../Card/TitleCard";
import InputText from "../Input/InputText";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import Dropdown from "../Input/DropDown";
import share from "../Icons/share_3857028.png";
import { useAuth } from "../Context/AuthContext";
import OrganizationForm from "../MasterFrom/OrganizationForm";
import EntityMasterForm from "../MasterFrom/EntityMasterForm";
import Representativesicon from "../Icons/representative.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { motion } from "framer-motion";
import { SiAwsorganizations } from "react-icons/si";

const Organizationpage = () => {
  const selectedRowData = {};
  const { userdata } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [Representatives, setRepresentatives] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showOrgForm, setShowOrgForm] = useState(false);
  const [showEntityForm, setShowEntityForm] = useState(false);
  const [fromDate, setFromDate] = useState(getOneWeekAgoDate());
  const [toDate, setToDate] = useState(getDate());
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchRepresentatives = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/BindGridAtlysEnityMaster`
        );
        setRepresentatives(res.data);
      } catch (error) {
        console.error("Failed to fetch representatives:", error);
      }
    };

    const fetchAllUserData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/getOrganizationCardInfo`,
          {
            Representatives: "",
            ShowMore: "false",
            FromDate: fromDate,
            ToDate: toDate,
            FilterCriteria: "",
          }
        );
        setUsers(response.data.data || []);
        setFilteredUsers(response.data.data || []);
      } catch (err) {
        setError("Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };

    fetchRepresentatives();
    fetchAllUserData();
  }, [fromDate, toDate]);

  useEffect(() => {
    if (location.state) {
      const { UserID, fromDate, ToDate } = location.state;
      setSelectedEmployee(UserID);
      if (ToDate) setToDate(ToDate);
      if (fromDate) setFromDate(fromDate);
    }
  }, [location.state]);

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedEmployee(selectedValue);
    if (selectedValue) {
      const filtered = users.filter((user) => user.EntityID === selectedValue);
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  };

  const handleLeadPreview = (user) => {
    navigate("/Organizationpage/OrgCardpage", {
      state: { user, toDate, Representatives, fromDate, orgId: user.OrgID },
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = users.filter(
      (user) =>
        (user.Title || "").toLowerCase().includes(query) ||
        (user.NameOfServiceProvider || "").toLowerCase().includes(query) ||
        (user.SourceCountry || "").toLowerCase().includes(query) ||
        (user.EntityName || "").toLowerCase().includes(query)
    );
    setFilteredUsers(filtered);
  };

  function formatDateToInput(dateStr) {
    const [day, month, year] = dateStr.split("-");
    const monthIndex = new Date(`${month} 1, 2000`).getMonth() + 1;
    const monthFormatted = monthIndex.toString().padStart(2, "0");
    return `${year}-${monthFormatted}-${day}`;
  }

  function formatDateFromInput(dateStr) {
    const [year, month, day] = dateStr.split("-");
    const monthName = new Date(`${year}-${month}-${day}`)
      .toLocaleString("en-US", { month: "short" })
      .toLowerCase();
    return `${day}-${monthName}-${year}`;
  }

  function getDate() {
    const today = new Date();
    return formatDateFromInput(today.toISOString().split("T")[0]);
  }

  function getOneWeekAgoDate() {
    const today = new Date();
    today.setDate(today.getDate() - 7);
    return formatDateFromInput(today.toISOString().split("T")[0]);
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <TitleCard>
      <div className="flex justify-between">
        <div className="flex-grow"></div>
        <div className="relative" ref={dropdownRef}>
          <button
            className="text-gray-800 font-bold py-2 px-3 sm:px-4 rounded-full inline-flex items-center"
            onClick={toggleDropdown}
          >
            <BsThreeDotsVertical className="w-6 h-6 text-black" />
          </button>
          {showDropdown && (
            <motion.div
              className=""
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
            >
              <div className="absolute right-2 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                {userdata?.IsSuperUser !== "0" && (
                  <Link
                    to="/Dashboard"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    <div className="flex items-center gap-2">
                      <MdOutlineDashboardCustomize />
                      <p>Dashboard</p>
                    </div>
                  </Link>
                )}
                <button
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => {
                    setShowOrgForm(true);
                    setShowEntityForm(false);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <SiAwsorganizations />
                    <p>Organization</p>
                  </div>
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  onClick={() => {
                    setShowEntityForm(true);
                    setShowOrgForm(false);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={Representativesicon}
                      className="w-4 h-4 inline"
                      alt="Representatives"
                    />
                    <p>Representatives</p>
                  </div>
                </button>
              </div>
            </motion.div>
          )}
        </div>
      </div>
      <div>
        {showOrgForm ? (
          <div>
            <h1 className="text-xl font-semibold">Organization</h1>
            <OrganizationForm
              changeForms={() => setShowOrgForm(false)}
              selectedRowData={selectedRowData}
            />
          </div>
        ) : showEntityForm ? (
          <div>
            <h1 className="text-xl font-semibold">Representatives</h1>
            <EntityMasterForm changeForms={() => setShowEntityForm(false)} />
          </div>
        ) : (
          <div>
            <div className="flex flex-col md:flex-row justify-between gap-2 mt-4">
              <Dropdown
                name="Representative"
                labelTitle="Representative"
                value={selectedEmployee}
                options={Representatives || []}
                labelText="Name"
                labelValue="EntityID"
                onChange={handleDropdownChange}
              />
              <InputText
                name="FromDate"
                type="date"
                updateFormValue={(name, value) =>
                  setFromDate(formatDateFromInput(value))
                }
                labelTitle="From Date"
                value={formatDateToInput(fromDate)}
              />
              <InputText
                name="ToDate"
                type="date"
                updateFormValue={(name, value) =>
                  setToDate(formatDateFromInput(value))
                }
                labelTitle="To Date"
                value={formatDateToInput(toDate)}
              />
            </div>

            {/* Search Input */}
            <div className="mt-4">
              <input
                type="text"
                placeholder="Search Users..."
                value={searchQuery}
                onChange={handleSearch}
                className="border-slate-300 rounded-md h-10 p-0 pl-3 border-2 sm:mt-2 w-full my-2"
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 mt-4">
              {filteredUsers.map((user) => (
                <div
                  onClick={() => handleLeadPreview(user)}
                  key={user.OrgID}
                  className="bg-white cursor-pointer dark:bg-slate-800 rounded-lg shadow-md hover:shadow-xl hover:scale-105 ease-out duration-100 overflow-hidden border"
                >
                  <figure className="relative p-3 flex justify-between">
                    <div className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-lg">
                      <div className="text-gray-600 hover:text-gray-900 dark:text-gray-300 cursor-pointer">
                        <img src={share} alt="" className="h-4" />
                      </div>
                    </div>
                    <div>
                      <div className="flex gap-4">
                        <div>
                          <div className="text-lg font-semibold text-slate-900 dark:text-slate-200">
                            {user.Title || "Not Available"}
                          </div>
                          <div className="text-slate-600 dark:text-slate-300 text-xs">
                            {user.SubHeading || "NA"}
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between flex-col">
                        <div className="mt-2">
                          <div className="flex gap-2 text-xs p-1">
                            <p>Source Country</p>
                            <div className="text-xs text-gray-500">
                              {user.SourceCountry || "NA"}
                            </div>
                          </div>
                          <div className="flex gap-2 text-xs p-1">
                            <p>City</p>
                            <div className="text-xs text-gray-500">
                              {user.City || "NA"}
                            </div>
                          </div>
                          <div className="flex gap-2 text-xs p-1">
                            <p>Visas outsourced</p>
                            <div className="text-xs text-gray-500">
                              {user.IsVisaSolutionsOutsourced || "NA"}
                            </div>
                          </div>
                          <div className="flex gap-2 text-xs p-1">
                            <p>Name Of Service Provider</p>
                            <div className="text-xs text-gray-500">
                              {user.NameOfServiceProvider || "NA"}
                            </div>
                          </div>
                          <div className="flex gap-2 text-xs p-1">
                            <p>Contract Validity Period</p>
                            <div className="text-xs text-gray-500">
                              {user.CurrentContractValidityPeriod || "NA"}
                            </div>
                          </div>
                          <div className="flex gap-2 text-xs p-1">
                            <p>Name of Representatives</p>
                            <div className="text-xs text-gray-500">
                              {user.EntityName || "NA"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </TitleCard>
  );
};

export default Organizationpage;
